import { ESortOrder } from "store/common/types";

export enum ENetworkRequestStatus {
  IDLE = 'IDLE',
  PENDING = 'LOADING',
  SUCCESS = 'LOADED',
  ERROR = 'ERROR',
  CANCELLED = 'CANCELLED',
}

export interface IPage {
  limit: number;
  offset: number;
}

export interface ISort<T> {
  key: T;
  order: ESortOrder;
}
