export enum ESortOrder {
  ASC = 'asc',
  DESC = 'desc',
}

export interface IPagination {
  limit: number;
  offset: number;
}

export interface IPaginationFE {
  currentPage: number;
  itemsPerPage: number;
}

export interface ISorting<TSortBy> {
  sortBy: TSortBy;
  sortOrder: ESortOrder;
}