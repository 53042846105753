import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { PageTitle } from 'ui/PageTitle';
import { Table } from 'ui/Table';
import { ITableColumn, ITableRow } from 'ui/Table/types';
import { formatDateDisplay } from 'utils';
import { ESortOrder } from 'store/common/types';
import { Pagination } from 'pureUi/Pagination';
import classnames from 'classnames';
import { edit } from 'ui/Icons';
import { Tooltip } from 'containers/TravelAgents/List/Tooltip';
import { CircleIconButton } from 'ui/CircleIconButton';
import { LoadingBar } from 'ui/NetworkStatusBar';
import * as AuthSelectors from 'store/modules/auth';
import { useHistory } from 'react-router';
import { ECrmDirectoryEntryType, ECrmDirectoryListSortableKey } from 'services/CrmApi/types/DirectoryEntry';
import { useResponsive } from '../hooks/useResponsive';
import { ContactIcon } from 'ui/Icons/components/CrmContact.component';
import { theme } from '../../../../tailwind.config';
import { CompanyIcon } from 'ui/Icons/components/Company.component';
import { ProgressBar } from 'ui/ProgressBar';
import { Rating } from 'react-simple-star-rating';
import { getPageResultsText } from 'utils/string';
import Flag from 'react-world-flags';
import FluidButton from 'ui/FluidButton';
import { IUseCrmDirectoryEntriesParams, useCrmDirectoryEntries } from 'services/CrmApi/queries/useCrmDirectoryEntries';
import classNames from 'classnames';
import { usePagination } from 'hooks/usePagination';
import { useSorting } from 'hooks/useSorting';

const directoryEntryTypeMapping = {
  [ECrmDirectoryEntryType.PERSON]: <ContactIcon fill={theme.colors['gray-60']} />,
  [ECrmDirectoryEntryType.COMPANY]: <CompanyIcon fill={theme.colors['gray-60']} />,
}

export const CrmDirectory: React.FC = () => {
  const history = useHistory();
  const { isMobile, isTablet } = useResponsive();
  const isSr = useSelector(AuthSelectors.isSr);

  const { sortBy, sortOrder, handleSort } = useSorting(ECrmDirectoryListSortableKey.CREATED_AT, ESortOrder.DESC)
  const {
    page: currentPage,
    setPage: setCurrentPage,
    perPage: itemsPerPage,
    setPerPage: setItemsPerPage,
    pageCount,
    setTotal
  } = usePagination({ initialPage: 0, initialPerPage: 10, initialTotal: 0 });

  const params: IUseCrmDirectoryEntriesParams = {
    pagination: {
      currentPage,
      itemsPerPage,
    },
    sorting: {
      sortBy,
      sortOrder,
    }
  }
  const { directoryEntries, directoryEntriesCount, crmDirectoryEntries } = useCrmDirectoryEntries(params);

  useEffect(() => {
    if (directoryEntriesCount !== null) {
      setTotal(directoryEntriesCount);
    }
  }, [directoryEntriesCount, setTotal]);

  const handleCreateCompany = useCallback(() => {
    history.push('/crm/company/create');
  }, [history]);

  const handleCreateContact = useCallback(() => {
    history.push('/crm/contact/create');
  }, [history]);

  const handlePageChange = useCallback((pageNumber: number) => {
    setCurrentPage(pageNumber - 1);
  }, [setCurrentPage]);

  const handleItemsPerPageChange = useCallback((itemsPerPage: number) => {
    setCurrentPage(0);
    setItemsPerPage(itemsPerPage);
  }, [setCurrentPage, setItemsPerPage]);

  const handleEditDirectoryEntry = useCallback(
    (directoryEntryId: string) => {
      history.push(`/crm/directory-entries/${directoryEntryId}/edit`)
    },
    [history]
  );

  const columns: ITableColumn[] = useMemo(() => {
    return [
      { id: 'name', label: 'NAME', width: isMobile ? '50px' : '100px', fieldToSortBy: ECrmDirectoryListSortableKey.NAME },
      { id: 'type', label: 'TYPE', width: '60px', className: 'text-ellipsis overflow-hidden', fieldToSortBy: ECrmDirectoryListSortableKey.PROFILE_TYPE, isHidden: isMobile },
      { id: 'manager', label: 'MANAGER', width: '80px', fieldToSortBy: ECrmDirectoryListSortableKey.MANAGER, isHidden: isMobile || isTablet },
      { id: 'country', label: 'COUNTRY', width: '20px', fieldToSortBy: ECrmDirectoryListSortableKey.COUNTRY },
      { id: 'stage', label: 'STAGE', width: isMobile ? '15px' : '35px', fieldToSortBy: ECrmDirectoryListSortableKey.STAGE, className: 'pl-[7px]' },
      { id: 'score', label: 'SCORE', width: isMobile ? '25px' : '30px', className: 'pl-[16px]', fieldToSortBy: ECrmDirectoryListSortableKey.SCORE },
      { id: 'created', label: 'CREATED', width: '25px', fieldToSortBy: ECrmDirectoryListSortableKey.CREATED_AT, isHidden: isMobile || isTablet },
      { id: 'actions', label: '', width: '20px', isHidden: isMobile }
    ];
  }, [isMobile, isTablet])

  const rows: ITableRow[] = useMemo(() => {
    if (!directoryEntries) {
      return [];
    }
    return directoryEntries.map(directoryEntry => {
      const name = (
        <div className={classnames('flex items-center gap-[10px]')}>
          {directoryEntryTypeMapping[directoryEntry.directoryEntryType]}
          <span className="">{directoryEntry.name}</span>
        </div>
      );
      const managerName = [directoryEntry.manager.firstName, directoryEntry.manager.lastName]
        .filter(Boolean)
        .join(' ');
      const country = directoryEntry.country ? (
        <Flag code={directoryEntry.country.code} style={{ width: 18, height: 18 }} />
      ) : null;
      const stage = (
        <div className="w-[50px]">
          <ProgressBar score={directoryEntry.leadStage?.order ?? 0} total={4} />
        </div>
      );
      const score = (
        <Rating
          size={14}
          initialValue={directoryEntry.leadScore}
          transition
          readonly
        />
      )
      const actions = (
        <div className="item-actions flex items-center relative">
          <div className="w-4px h-34px rounded bg-brown-15 mr-10px" />
          <Tooltip content="Edit">
            <CircleIconButton
              type="secondary"
              className="edit-action mx-1"
              iconClass="inline relative left-0 top-1px w-12px h-12px"
              iconSrc={edit}
              onClick={() => handleEditDirectoryEntry(directoryEntry.profileId)}
            />
          </Tooltip>
        </div>
      );
      return {
        id: directoryEntry.profileId,
        cells: [
          { id: 'name', value: name, title: directoryEntry.name },
          { id: 'type', value: directoryEntry.profileType.value, title: directoryEntry.profileType.value, className: '', isHidden: isMobile },
          { id: 'manager', value: managerName, title: managerName, isHidden: isMobile || isTablet },
          { id: 'country', value: country, title: directoryEntry.country?.name ?? '', className: 'pl-[24px]' },
          { id: 'stage', value: stage, title: directoryEntry.leadStage?.value ?? '', className: '' },
          { id: 'score', value: score, title: String(directoryEntry.leadScore), className: '' },
          { id: 'created', value: formatDateDisplay(directoryEntry.createdAt), title: formatDateDisplay(directoryEntry.createdAt), isHidden: isMobile || isTablet },
          { id: 'actions', value: actions, isHidden: isMobile },
        ]
      }
    })
  }, [directoryEntries, isMobile, isTablet, handleEditDirectoryEntry])
  
  if (crmDirectoryEntries.isPending || !directoryEntries) {
    return (
      <div className="mt-[20px]">
        <LoadingBar />
      </div>
    )
  }
  
  const paginationClassname = classNames({
    'flex flex-col gap-[15px]': isMobile || isTablet,
  });
  
  return (
    <div className="crm-directory mx-[20px] crm-desktop:mx-[80px]">
      <PageTitle
        primaryText="Directory"
        secondaryText={getPageResultsText(crmDirectoryEntries.isFetching, directoryEntriesCount)}
        secondaryCondition={true}
      />
      <div className="button-container flex justify-between mt-[15px]">
        <div className="buttons-container-left flex items-center gap-[10px]">
          <FluidButton type="secondary" textClassName="flex items-center gap-[10px]" onClick={() => {}}>
            {isMobile ? 'Reset' : 'Reset Filters'}
          </FluidButton>
          <FluidButton type="primary" textClassName="flex items-center gap-[10px]" onClick={() => {}}>
            Search
          </FluidButton>
        </div>
        <div className="buttons-container-right flex items-center gap-[10px]">
          <FluidButton type="primary" textClassName="flex items-center gap-[10px]" onClick={handleCreateCompany} disabled={isSr}>
            New Company
            {isMobile ? null : <CompanyIcon fill={theme.colors['white']} />}
          </FluidButton>
          <FluidButton type="primary" textClassName="flex items-center gap-[10px]" onClick={handleCreateContact} disabled={isSr}>
            New Contact
            {isMobile ? null : <ContactIcon fill={theme.colors['white']} />}
          </FluidButton>
        </div>
      </div>

      <div className="mt-[20px]">
        <div className="task-list-content w-full">
          <Table
            columns={columns}
            rows={rows}
            sortBy={sortBy}
            sortOrder={sortOrder}
            onSort={handleSort}
            messageWhenEmpty="No results"
            evenColor="ivory"
            loading={crmDirectoryEntries.isFetching}
          />
          {directoryEntriesCount !== null && directoryEntriesCount > 0 && (
            <div className="mt-[30px]">
              <Pagination
                itemsPerPage={itemsPerPage}
                pageCount={pageCount}
                currentPage={currentPage + 1}
                className={paginationClassname}
                onPageSelect={handlePageChange}
                onItemsPerPageChange={handleItemsPerPageChange}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
