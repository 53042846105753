import { useCallback, useState } from "react";
import { ESortOrder } from "store/common/types";

export const useSorting = <TSortBy>(initialSortBy: TSortBy, initialSortOrder: ESortOrder = ESortOrder.ASC) => {
  const [sortBy, setSortBy] = useState(initialSortBy);
  const [sortOrder, setSortOrder] = useState(initialSortOrder);
  
  const handleSort = useCallback((newSortBy: TSortBy, newSortOrder: ESortOrder) => {
    setSortBy(newSortBy);
    setSortOrder(newSortOrder);
  }, []);
  
  return { sortBy, sortOrder, handleSort }
}