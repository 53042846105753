import { ICompany, IUploadFileInfo } from 'services/BackendApi';
import { IBootstrapCountry } from 'services/BackendApi/types/country';
import { IDynamicParametersResponse } from 'services/ParametersProviderApi';

export interface IBootstrapHotel {
  uuid: string;
  name: string;
  countryCode: string;
}

export interface IBootstrapExtraPersonSupplementProduct {
  uuid: string;
  name: string;
}

export interface IBootstrapModule {
  countries: IBootstrapCountry[];
  hotels: IBootstrapHotel[];
  extraPersonSupplementProduct: IBootstrapExtraPersonSupplementProduct;
  dynamicParameters: IDynamicParametersResponse;
  mainCompanyLogo: IUploadFileInfo | null;
  mainCompany: ICompany | null;
  error: any;
}

export const initialState: IBootstrapModule = {
  countries: [],
  hotels: [],
  extraPersonSupplementProduct: {
    uuid: '',
    name: '',
  },
  dynamicParameters: {},
  mainCompanyLogo: null,
  mainCompany: null,
  error: null,
};
