import React from 'react';
import { RHPSubtitle } from './RHPSubtitle';
import { ETransferDirection } from 'store/modules/bookingBuilder/subdomains/transfers/types';
import { IBookingError } from 'services/BackendApi';
import classNames from 'classnames';

const getProductName = (product: { uuid: string; name: string; direction: ETransferDirection }) => {
  const directionMapping = {
    in: '(IN)',
    out: '(OUT)',
    return: '',
  };
  const productName = [product.name, directionMapping[product.direction]].filter(Boolean).join(' ');
  return productName;
};

export interface IRHPTransfersProps {
  isMandatory: boolean;
  products: { uuid: string; name: string; direction: ETransferDirection }[];
  isVisible: boolean;
  transferErrors: IBookingError[];
}

export const RHPTransfers: React.FC<IRHPTransfersProps> = ({ isMandatory, products, isVisible, transferErrors }) => {
  if (!isVisible) {
    return null;
  }

  let title = 'Transfers';
  if (isMandatory) {
    title += ' *';
  }

  const oneWayInTransfer = products.find(item => item.direction === ETransferDirection.IN);
  const oneWayOutTransfer = products.find(item => item.direction === ETransferDirection.OUT);
  const returnTransfer = products.find(item => item.direction === ETransferDirection.RETURN);

  return (
    <div className="rhp-transfers">
      <RHPSubtitle>{title}</RHPSubtitle>
      {(oneWayInTransfer || oneWayOutTransfer) && (
        <>
          <p className="m-0 mt-[15px] font-pt-sans text-[12px] text-gray-100 leading-[normal] tracking-[0.48px] uppercase">
            ONE WAY (IN)
          </p>
          {oneWayInTransfer && <p className="rhp-transfer m-0 mt-[5px]">{getProductName(oneWayInTransfer)}</p>}
        </>
      )}
      {(oneWayInTransfer || oneWayOutTransfer) && (
        <>
          <p className="m-0 mt-[15px] font-pt-sans text-[12px] text-gray-100 leading-[normal] tracking-[0.48px] uppercase">
            ONE WAY (OUT)
          </p>
          {oneWayOutTransfer && <p className="rhp-transfer m-0 mt-[5px]">{getProductName(oneWayOutTransfer)}</p>}
        </>
      )}
      {returnTransfer && (
        <>
          <p className="m-0 mt-[15px] font-pt-sans text-[12px] text-gray-100 leading-[normal] tracking-[0.48px] uppercase">
            RETURN TRANSFER
          </p>
          <p className="rhp-transfer m-0 mt-[5px]">{getProductName(returnTransfer)}</p>
        </>
      )}

      {products.length === 0 && (
        <p className="font-pt-sans text-[15px] italic leading-[normal] text-gray-100 mt-[5px] mb-0">None added</p>
      )}

      {transferErrors.length > 0 && (
        <div className="flex flex-col gap-[5px]">
          {transferErrors.map(error => (
            <p
              className={classNames('font-pt-sans text-[13px] italic leading-[15px] mt-[5px] mb-0', {
                'text-red-95': error.type === 'booking',
                'text-yellow-60': error.type === 'warn',
              })}
            >
              {error.message}
            </p>
          ))}
        </div>
      )}
    </div>
  );
};
