import { Field, Formik, FormikHelpers, FormikProps } from 'formik';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { BackLink } from 'ui/BackLink';
import { PageTitle } from 'ui/PageTitle';
import { contactCreateFormValidationSchema, ICompanyCreateFormValues } from './contactCreateFormValidation';
import classnames from 'classnames';
import { useDispatch } from 'react-redux';
import { LeaveWithoutSavingModal } from 'ui/LeaveWithoutSavingModal';
import { TextInput } from 'ui/TextInput';
import Editor from 'pureUi/Editor';
import SingleSelect, { ISingleSelectOption } from 'ui/SingleSelect';
import { makeCRMApi } from 'services/CrmApi';
import { ETypeValueType } from 'services/CrmApi/types/types';
import { PageSectionTitle } from 'ui/PageSectionTitle';
import { VerticalSpace } from 'ui/VerticalSpace';
import FluidButton from 'ui/FluidButton';
import { enqueueNotification } from 'store/modules/ui';
import { useHistory } from 'react-router';
import { useQuery, useMutation } from '@tanstack/react-query';
import { ErrorBar, LoadingBar } from 'ui/NetworkStatusBar';
import { useResponsive } from '../hooks/useResponsive';
import { INewContactRequest } from 'services/CrmApi/types/CrmContactTypes';
import { useCrmCountries } from 'services/CrmApi/queries/useCrmCountries';
import { useCrmTypeValues } from 'services/CrmApi/queries/useCrmTypeValues';
import { useCreateContactMutation } from 'services/CrmApi/mutations/useCreateContactMutation';
import { useNotifications } from 'hooks/useNotifications';
import { delay } from 'utils/promise';
import { useDefaultOnErrorHandler } from 'services/CrmApi/mutations/defaultOnErrorHandler';

export const CrmContactCreate = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { isMobile } = useResponsive();
  const { showSuccessNotification } = useNotifications();
  const [ isRedirectReady, setIsRedirectReady ] = useState<boolean>(false);
  const { defaultOnErrorHandler } = useDefaultOnErrorHandler();

  const crmApi = makeCRMApi();
  const crmCountries = useCrmCountries();
  const crmTypeValuesContacts = useCrmTypeValues(ETypeValueType.CONTACT, { enabled: true });
  const { isPending: isTitleValuesPending, isError: isTitleValuesError, data: titleValues } = useQuery({
    queryKey: ['crm-title-values'],
    queryFn: () =>
      crmApi.postTitleValues(ETypeValueType.TITLE, { enabled: true }),
  });

  const createContactMutation = useCreateContactMutation();
  useEffect(() => {
    if(isRedirectReady) {
      history.push('/crm');
    }    
  }, [history, isRedirectReady]);

  const initialValues: ICompanyCreateFormValues = useMemo(() => ({
    type: '',
    title: null,
    firstName: '',
    lastName: null,
    country: null,
    email: null,
    mobile: null,
    landline: null,
    keyNotes: null,
  }), []);

  const handleFormSubmit = useCallback(async (values: ICompanyCreateFormValues, formikHelpers: FormikHelpers<ICompanyCreateFormValues>) => {
    const requestData : INewContactRequest = {
      // BE is not accepting null values
      typeCode: values.type,
      titleCode: values.title || undefined,
      firstName: values.firstName,
      lastName: values.lastName || undefined,
      countryCode: values.country || undefined,
      email: values.email || undefined,
      mobile: values.mobile || undefined,
      landline: values.landline || undefined,
      keyNotes: values.keyNotes || undefined,
    }
    
    try {
      await createContactMutation.mutateAsync(requestData);
      formikHelpers.resetForm({values: initialValues});
      // Formik resetForm is executed asynchronously even though it is not an async function
      await delay(0);
      history.push('/crm');
      showSuccessNotification('Contact created.');      
    } catch (error) {
      defaultOnErrorHandler(error, 'Failed to create a Contact');
    }
  }, [createContactMutation, defaultOnErrorHandler, history, initialValues, showSuccessNotification]);

  const countryOptions: ISingleSelectOption[] = crmCountries.data?.data?.map(
    item => ({ value: item.code, label: item.name })
  ) ?? [];

  const typeOptions: ISingleSelectOption[] = crmTypeValuesContacts.data?.data?.map(
    item => ({ value: item.code, label: item.value })
  ) ?? [];

  const titleOptions: ISingleSelectOption[] = titleValues?.data?.map(
    item => ({ value: item.code, label: item.value })
  ) ?? [];

  if (crmCountries.isPending || crmTypeValuesContacts.isPending || isTitleValuesPending) {
    return (
      <div className="mt-[20px]">
        <LoadingBar />
      </div>
    )
  }

  if (crmCountries.isError || crmTypeValuesContacts.isError || isTitleValuesError) {
    return (
      <div className="mt-[20px]">
        <ErrorBar />
      </div>
    )
  }

  const selectClassname = classnames('min-w-[200px]', {
    'w-full': isMobile,
    'w-[200px]': !isMobile,
  });
  const namesClassname = classnames('min-w-[300px]', {
    'w-full': isMobile,
    'w-[300px]': !isMobile,
  });
  const emailsClassname = classnames('min-w-[420px]', {
    'w-full': isMobile,
    'w-[420px]': !isMobile,
  });
  const phonesClassname = classnames('min-w-[200px]', {
    'w-full': isMobile,
    'w-[200px]': !isMobile,
  });
  const saveButtonClassname = classnames({
    'w-full': isMobile,
  });
  
  return (
    <div className="crm-directory mx-[20px] crm-desktop:mx-[80px]">
      <BackLink type="internal" href="/crm">
        Back
      </BackLink>
      <PageTitle
        primaryText="New Contact"
        secondaryCondition={false}
      />
      <VerticalSpace height="20px" />
      <PageSectionTitle
        text="Basic Info"
      />
      <Formik
          initialValues={initialValues}
          validationSchema={contactCreateFormValidationSchema}
          enableReinitialize={true}
          onSubmit={handleFormSubmit}
        >
          {(form: FormikProps<ICompanyCreateFormValues>) => {  
            return (
              <>
                <LeaveWithoutSavingModal 
                  when={form.dirty} 
                  confirmButtonLabel="Yes"
                  cancelButtonLabel="No"
                />

                <form autoComplete={'off'} onSubmit={form.handleSubmit}>
                  <div className="form-container flex flex-col justify-between gap-[20px] mt-[10px]">
                    <div className="type-title-firstname-lastname-country flex flex-col crm-tablet:flex-row items-start gap-[20px]">
                      <Field name="type">
                        {({ field: { name, value }, form: { setFieldValue } }) => (
                          <SingleSelect
                            fieldId="type"
                            label="Type *"
                            className={classnames('type', selectClassname)}
                            value={value}
                            showSelectedOption
                            maxVisibleItems={isMobile ? 4 : 6}
                            options={typeOptions ?? []}
                            labelWhenNothingSelected="None"
                            onChange={value => {
                              setFieldValue(name, value);
                            }}
                            errorMessage={form.touched.type && form.errors.type ? form.errors.type : null}
                            errorClassName="type-error"
                          />
                        )}
                      </Field>
                      <Field name="title">
                        {({ field: { name, value }, form: { setFieldValue } }) => (
                          <SingleSelect
                            fieldId="title"
                            label="Title"
                            className={classnames('type', selectClassname)}
                            value={value}
                            showSelectedOption
                            maxVisibleItems={isMobile ? 4 : 6}
                            options={titleOptions ?? []}
                            labelWhenNothingSelected="None"
                            onChange={value => {
                              setFieldValue(name, value);
                            }}
                            errorMessage={form.touched.title && form.errors.title ? form.errors.title : null}
                            errorClassName="title-error"
                          />
                        )}
                      </Field>
                      <Field
                        as={TextInput}
                        type="text"
                        name="firstName"
                        id="firstName"
                        className={classnames('first-name', namesClassname)}
                        label="First Name *"
                        errorMessage={form.touched.firstName && form.errors.firstName ? form.errors.firstName : null}
                        errorClassName="first-name-error"
                      />
                      <Field
                        as={TextInput}
                        type="text"
                        name="lastName"
                        id="lastName"
                        className={classnames('last-name', namesClassname)}
                        label="Last Name"
                        errorMessage={form.touched.lastName && form.errors.lastName ? form.errors.lastName : null}
                        errorClassName="last-name-error"
                      />
                      <Field name="country">
                        {({ field: { name, value }, form: { setFieldValue } }) => (
                          <SingleSelect
                            fieldId="country"
                            label="Country"
                            className={classnames('country', selectClassname)}
                            value={value}
                            maxVisibleItems={isMobile ? 4 : 6}
                            showSelectedOption
                            options={countryOptions ?? []}
                            labelWhenNothingSelected="None"
                            onChange={value => {
                              setFieldValue(name, value);
                            }}
                            errorMessage={form.touched.country && form.errors.country ? form.errors.country : null}
                            errorClassName="country-error"
                          />
                        )}
                      </Field>
                    </div>
                    <div className="email-mobile-landline flex flex-col crm-tablet:flex-row items-start gap-[20px]">
                      <Field
                        as={TextInput}
                        type="text"
                        name="email"
                        id="email"
                        className={classnames('email', emailsClassname)}
                        label="E-mail"
                        errorMessage={form.touched.email && form.errors.email ? form.errors.email : null}
                        errorClassName="email-error"
                      />
                      <Field
                        as={TextInput}
                        type="text"
                        name="mobile"
                        id="mobile"
                        className={classnames('mobile', phonesClassname)}
                        label="Mobile"
                        errorMessage={form.touched.mobile && form.errors.mobile ? form.errors.mobile : null}
                        errorClassName="mobile-error"
                      />
                      <Field
                        as={TextInput}
                        type="text"
                        name="landline"
                        id="landline"
                        className={classnames('landline', phonesClassname)}
                        label="Landline"
                        errorMessage={form.touched.landline && form.errors.landline ? form.errors.landline : null}
                        errorClassName="landline-error"
                      />
                    </div>
                    <div className="key-notes flex  gap-[20px]">
                      <Field name="keynotes">
                        {({ field: { name, value }, form: { setFieldValue } }) => (
                          <div className="flex flex-col w-full">
                            <label className="font-pt-sans mb-5px text-black text-13px leading-17px tracking-2xs">
                              Key Notes
                            </label>
                            <Editor text={value} handleEditorChange={(value) => setFieldValue(name, value)} options={[]} mentionAbility={false} />
                          </div>
                        )}
                      </Field>
                    </div>
                  </div>

                <div className="buttons-container mt-[20px]">
                  <FluidButton submit type="primary" textClassName="flex items-center gap-[10px]" className={saveButtonClassname}>
                     Save
                  </FluidButton>
                </div>
                </form>
              </>
            );
          }}
        </Formik>
    </div>
  )
};
