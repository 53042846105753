import { useDispatch } from "react-redux";
import { enqueueNotification } from "store/modules/ui";

export const useNotifications = () => {
  const dispatch = useDispatch();
  const showSuccessNotification = (message: string) => {
    dispatch(
        enqueueNotification({
          message: message,
          options: { variant: 'success' },
        })
      );
  }
  const showErrorNotification = (message: string) => {
    dispatch(
        enqueueNotification({
          message: message,
          options: { variant: 'error' },
        })
      );
  }

  return { showSuccessNotification, showErrorNotification };
}
