import React from 'react';
import * as Inputs from './Inputs';
import {
  IAccommodationProduct,
  IAccomodationProductOptions,
  IHotel,
  IProduct,
  ISeasonalProductAddonRate,
  ISeasonalProductRate,
} from 'services/BackendApi';
import FluidButton from 'ui/FluidButton';
import { IBootstrapExtraPersonSupplementProduct } from 'store/modules/bootstrap/model';
import { produce } from 'immer';
import * as _ from 'lodash-es';
import { SeasonalProductAddonRate } from './SeasonalProductAddonRate';
import { IBootstrapCountry } from 'services/BackendApi/types/country';

export const RoomRateDetails = ({
  hotel,
  room,
  seasonalProductRate,
  bootstrapCountries,
  onUpdateSeasonalProductRate,
  onCta,
  ctaLabel,
  epsProduct,
}: {
  hotel: IHotel;
  room: IAccommodationProduct;
  seasonalProductRate: Partial<ISeasonalProductRate>;
  bootstrapCountries: IBootstrapCountry[];
  onUpdateSeasonalProductRate: (field: keyof ISeasonalProductRate, value: any) => void;
  onCta: () => void;
  ctaLabel: string;
  epsProduct: IBootstrapExtraPersonSupplementProduct;
}) => {
  const allSeasons = hotel.seasons || [];

  const markupTypes = [
    { value: 'flat', label: 'Flat' },
    { value: 'percentage', label: 'Percentage' },
  ];

  const extraPersonSupplementRate: ISeasonalProductAddonRate | undefined = (
    seasonalProductRate.seasonalProductAddonRates || []
  ).find(spar => spar.productUuid === epsProduct.uuid);

  const updateEpsRate = (field: string, val: any) => {
    const indexOfEpsRate = seasonalProductRate.seasonalProductAddonRates?.findIndex(
      spar => spar.productUuid === epsProduct.uuid
    );

    let epsRate: Partial<ISeasonalProductAddonRate> = {
      productUuid: epsProduct.uuid,
      isOnRequest: false,
    };

    if (indexOfEpsRate !== undefined) {
      epsRate = seasonalProductRate.seasonalProductAddonRates![indexOfEpsRate];
    }

    const updatedEpsRate = produce(epsRate, draftEpsRate => {
      _.set(draftEpsRate, field, val);
    });

    const updatedSeasonalProductAddonRates = produce(
      seasonalProductRate.seasonalProductAddonRates,
      draftSeasonalProductAddonRates => {
        if (draftSeasonalProductAddonRates === undefined) {
          draftSeasonalProductAddonRates = [];
        }
        if (indexOfEpsRate === undefined) {
          // @ts-ignore
          draftSeasonalProductAddonRates.push(updatedEpsRate);
        } else {
          // @ts-ignore
          draftSeasonalProductAddonRates[indexOfEpsRate] = updatedEpsRate;
        }

        return draftSeasonalProductAddonRates;
      }
    );

    onUpdateSeasonalProductRate('seasonalProductAddonRates', updatedSeasonalProductAddonRates);
  };

  return (
    <div className="flex flex-col space-y-4 w-full">
      <Inputs.Select
        label="Season"
        value={seasonalProductRate.seasonUuid || ''}
        onChange={val => {
          onUpdateSeasonalProductRate('seasonUuid', val);
        }}
        options={allSeasons.map(season => {
          return {
            value: season.uuid,
            label: season.name,
          };
        })}
      />

      <Inputs.Checkbox
        label="Is on request"
        value={seasonalProductRate.isOnRequest || false}
        onChange={val => {
          onUpdateSeasonalProductRate('isOnRequest', val);
        }}
      />

      {seasonalProductRate.isOnRequest === false && (
        <Inputs.Input
          label="Rate"
          value={seasonalProductRate.rate || ''}
          onChange={val => {
            onUpdateSeasonalProductRate('rate', val);
          }}
        />
      )}

      <Inputs.Input
        label="Markup Amount"
        value={seasonalProductRate.markupAmount || ''}
        onChange={val => {
          onUpdateSeasonalProductRate('markupAmount', val);
        }}
      />

      <Inputs.RadioCollection
        label="Markup Type"
        onChange={val => {
          onUpdateSeasonalProductRate('markupType', val);
        }}
        collection={markupTypes.map(markupType => {
          return {
            isChecked: markupType.value === seasonalProductRate.markupType,
            value: markupType.value,
            label: markupType.label,
          };
        })}
      />

      <Inputs.Input
        label="Minimum advance"
        value={seasonalProductRate.minimumAdvance ? seasonalProductRate.minimumAdvance.toString() : ''}
        onChange={val => {
          onUpdateSeasonalProductRate('minimumAdvance', parseInt(val));
        }}
      />

      <Inputs.Input
        label="Minimum nights"
        value={seasonalProductRate.minimumNights ? seasonalProductRate.minimumNights.toString() : ''}
        onChange={val => {
          onUpdateSeasonalProductRate('minimumNights', parseInt(val));
        }}
      />

      <Inputs.Input
        label="Internal description"
        value={seasonalProductRate.internalDescription || ''}
        onChange={val => {
          onUpdateSeasonalProductRate('internalDescription', val);
        }}
      />

      <Inputs.Input
        label="Payment terms"
        value={seasonalProductRate.paymentTerms || ''}
        onChange={val => {
          onUpdateSeasonalProductRate('paymentTerms', val);
        }}
      />

      <Inputs.Input
        label="Cancellation policy"
        value={seasonalProductRate.cancellationPolicy || ''}
        onChange={val => {
          onUpdateSeasonalProductRate('cancellationPolicy', val);
        }}
      />

      <p>Extra Person Supplement Rates</p>
      {extraPersonSupplementRate && (
        <SeasonalProductAddonRate
          addonRate={extraPersonSupplementRate}
          onUpdate={updateEpsRate}
          ageBrackets={room.options.ages}
        />
      )}

      <Inputs.CountryList
        selectedCountries={seasonalProductRate.countries ? seasonalProductRate.countries : []}
        label="Countries"
        bootstrapCountries={bootstrapCountries}
        onChange={val => {
          onUpdateSeasonalProductRate('countries', val);
        }}
      />

      <FluidButton
        type="primary"
        className="w-[250px] self-end"
        onClick={() => {
          onCta();
        }}
      >
        {ctaLabel}
      </FluidButton>
    </div>
  );
};
