import * as Yup from 'yup';

export const contactCreateFormValidationSchema = Yup.object().shape({
  type: Yup.string().required('Field is required'),
  title: Yup.string().nullable(true),
  firstName: Yup.string().required('Field is required'),
  lastName: Yup.string().nullable(true),
  country: Yup.string().nullable(true),
  email: Yup.string().nullable(true).email(),
  mobile: Yup.string().nullable(true),
  landline: Yup.string().nullable(true),
  keyNotes: Yup.string().nullable(true),
});

export interface ICompanyCreateFormValues {
  type: string;
  title: string | null;
  firstName: string;
  lastName: string | null;
  country: string | null;
  email: string | null;
  mobile: string | null;
  landline: string | null;
  keyNotes: string | null;
}