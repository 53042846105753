import classNames from 'classnames';
import React from 'react';

export interface IPageSectionTitleProps {
  text: string;
  className?: string;
}

export const PageSectionTitle: React.FC<IPageSectionTitleProps> = ({ 
  text, className = '',   
}) => {
  return (
    <div className="page-section-title font-pt-sans flex items-baseline">
      <h3 className={classNames('section-title font-pt-sans text-[17px] leading-normal text-black font-[700] m-0', className)}>
        {text}
      </h3>
    </div>
  );
}
