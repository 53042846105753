import { useQuery } from "@tanstack/react-query";
import { makeCRMApi } from "../CrmApi";
import { AxiosResponse } from "axios";
import { IBootstrapCountry } from "services/BackendApi/types/country";

export const useCrmCountries = () => {
  const crmApi = makeCRMApi();

  const crmCountries = useQuery<AxiosResponse<IBootstrapCountry[]>>({
    queryKey: ['crm-countries'],
    queryFn: () =>
      crmApi.postCountries(),
  });

  return crmCountries;
}
