import { createSelector } from 'reselect';
import { isEqual } from 'lodash-es';
import { IBookingManagerBreakdownSubdomain, IDateRange, IEditAccommodationForm, ILiveCancellationModalData } from './model';
import { breakdownSubdomainSelector } from '../../selectors';
import { IHeadlineLineItemBreakdown } from 'services/BookingManagerApi';
import { computeTextHash, computeSalesCostHash, computeSalesCost } from 'utils/breakdown';
import { addDays, differenceInCalendarDays } from 'date-fns';
import { formatDateWithoutOffset } from 'utils';
import { DateHelper } from 'pureUi/DatePicker';

export const headlineLineItemBreakdownSelector = createSelector(
  breakdownSubdomainSelector,
  (subdomain: IBookingManagerBreakdownSubdomain) => subdomain.headlineLineItemBreakdown
);

export const headlineLineItemBreakdownCommitedSelector = createSelector(
  breakdownSubdomainSelector,
  (subdomain: IBookingManagerBreakdownSubdomain) => subdomain.headlineLineItemBreakdownCommited
);

export const headlineLineItemBreakdownUpdatedAtSelector = createSelector(
  breakdownSubdomainSelector,
  (subdomain: IBookingManagerBreakdownSubdomain) => subdomain.headlineLineItemBreakdownUpdatedAt
);

export const breakdownNetworkRequestsSelector = createSelector(
  breakdownSubdomainSelector,
  (subdomain: IBookingManagerBreakdownSubdomain) => subdomain.networkRequests
);

export const isHeadlineBreakdownEditedWithoutSavingSelector = createSelector(
  breakdownSubdomainSelector,
  (subdomain: IBookingManagerBreakdownSubdomain) => subdomain.isHeadlineBreakdownEditedWithoutSaving
);

export const getBreakdownLogoSelector = createSelector(
  breakdownSubdomainSelector,
  (subdomain: IBookingManagerBreakdownSubdomain) => subdomain.breakdownLogo
);

export const headlineBreakdownVersionListSelector = createSelector(
  breakdownSubdomainSelector,
  (subdomain: IBookingManagerBreakdownSubdomain) => subdomain.headlineBreakdownVersionList
);

export const headlineBreakdownSelectedVersionSelector = createSelector(
  breakdownSubdomainSelector,
  (subdomain: IBookingManagerBreakdownSubdomain) => subdomain.headlineBreakdownSelectedVersion
);

export const headlineBreakdownRealLatestVersionSelector = createSelector(
  breakdownSubdomainSelector,
  (subdomain: IBookingManagerBreakdownSubdomain) => subdomain.headlineBreakdownRealLatestVersion
);

export const breakdownAddActivitySelector = createSelector(
  breakdownSubdomainSelector,
  (subdomain: IBookingManagerBreakdownSubdomain) => subdomain.activity
);

export const breakdownAncillaryTransferModalToggleSelector = createSelector(
  breakdownSubdomainSelector,
  (subdomain: IBookingManagerBreakdownSubdomain) => subdomain.isAddAncillaryTransfersModalOpen
);

export const breakdownAncillaryGroundServiceModalToggleSelector = createSelector(
  breakdownSubdomainSelector,
  (subdomain: IBookingManagerBreakdownSubdomain) => subdomain.isAddAncillaryGroundServiceModalOpen
);

export const breakdownAncillaryAccommodationModalToggleSelector = createSelector(
  breakdownSubdomainSelector,
  (subdomain: IBookingManagerBreakdownSubdomain) => subdomain.isAddAncillaryAccommodationModalOpen
);

export const breakdownCreateInvoiceSelector = createSelector(
  breakdownSubdomainSelector,
  (subdomain: IBookingManagerBreakdownSubdomain) => subdomain.createInvoice
);

export const breakdownCreateManualInvoiceSelector = createSelector(
  breakdownSubdomainSelector,
  (subdomain: IBookingManagerBreakdownSubdomain) => subdomain.createManualInvoice
);

export const isCreateQuoteModalOpenSelector = createSelector(
  breakdownSubdomainSelector,
  (subdomain: IBookingManagerBreakdownSubdomain) => subdomain.isCreateQuoteModalOpen
);

export const paymentTermsSelector = createSelector(
  breakdownSubdomainSelector,
  (subdomain: IBookingManagerBreakdownSubdomain) => subdomain.paymentTerms
);

export const cancellationPoliciesSelector = createSelector(
  breakdownSubdomainSelector,
  (subdomain: IBookingManagerBreakdownSubdomain) => subdomain.cancellationPolicies
);

export const policiesAndRestrictionsSelector = createSelector(
  breakdownSubdomainSelector,
  (subdomain: IBookingManagerBreakdownSubdomain) => subdomain.policiesAndRestrictions
);

export const offerTermsSelector = createSelector(
  breakdownSubdomainSelector,
  (subdomain: IBookingManagerBreakdownSubdomain) => subdomain.offerTerms
);

export const membershipSelector = createSelector(
  breakdownSubdomainSelector,
  (subdomain: IBookingManagerBreakdownSubdomain) => subdomain.membership
);

export const isPcrDetailsModalOpenSelector = createSelector(
  breakdownSubdomainSelector,
  (subdomain: IBookingManagerBreakdownSubdomain) => subdomain.isPcrDetailsModalOpen
);

export const isSaveToPerformActionModalOpenSelector = createSelector(
  breakdownSubdomainSelector,
  (subdomain: IBookingManagerBreakdownSubdomain) => subdomain.isSaveToPerformActionModalOpen
);

export const isBreakdownTextModifiedSelector = createSelector(
  headlineLineItemBreakdownCommitedSelector,
  headlineLineItemBreakdownSelector,
  (commited: IHeadlineLineItemBreakdown | null, current: IHeadlineLineItemBreakdown | null) => {
    if (!commited || !current) {
      return false;
    }
    return !isEqual(computeTextHash(commited), computeTextHash(current));
  }
);

export const isBreakdownSalesCostModifiedSelector = createSelector(
  headlineLineItemBreakdownCommitedSelector,
  headlineLineItemBreakdownSelector,
  (commited: IHeadlineLineItemBreakdown | null, current: IHeadlineLineItemBreakdown | null) => {
    if (!commited || !current) {
      return false;
    }
    return !isEqual(computeSalesCostHash(commited), computeSalesCostHash(current));
  }
);

export const isBreakdownCurrencyExchangeModifiedSelector = createSelector(
  headlineLineItemBreakdownCommitedSelector,
  headlineLineItemBreakdownSelector,
  (commited: IHeadlineLineItemBreakdown | null, current: IHeadlineLineItemBreakdown | null) => {
    if (!commited || !current) {
      return false;
    }
    delete commited.ExchangeRate?.expiry;
    delete current.ExchangeRate?.expiry;
    return !isEqual(commited.ExchangeRate, current.ExchangeRate);
  }
);

export const breakdownSalesCostDiffSelector = createSelector(
  headlineLineItemBreakdownCommitedSelector,
  headlineLineItemBreakdownSelector,
  (commited: IHeadlineLineItemBreakdown | null, current: IHeadlineLineItemBreakdown | null) => {
    if (!commited || !current) {
      return null;
    }

    return computeSalesCost(current) - computeSalesCost(commited);
  }
);

export const liveCancellationModalDataSelector = createSelector(
  breakdownSubdomainSelector,
  (subdomain: IBookingManagerBreakdownSubdomain) => subdomain.liveCancellationModalData
);

export const liveAccommodationCancellationPoliciesSelector = createSelector(
  breakdownSubdomainSelector,
  (subdomain: IBookingManagerBreakdownSubdomain) => subdomain.liveAccommodationCancellationPolicies
);

export const liveCancellationModalDataForPostBookingEndpointSelector = createSelector(
  liveCancellationModalDataSelector,
  (liveCancelModalData: ILiveCancellationModalData) => {
    const accommodations = liveCancelModalData.accoms.map(accom => ({
      bookingReference: accom.bookingRef,
      cancellationCostCents: accom.cancellationFeeCents,
      dueDate: accom.date,
    }));
    return {
      accommodations,
      skipExternalCancellation: !!liveCancelModalData.cancellationError
    };
  }
);

export const isLiveCancellationWarningModalOpenSelector = createSelector(
  breakdownSubdomainSelector,
  (subdomain: IBookingManagerBreakdownSubdomain) => subdomain.isLiveCancellationWarningModalOpen
);

export const paymentMethodsSelector = createSelector(breakdownSubdomainSelector, domain => domain.paymentMethods);

export const shouldUserNeedToConfirmCPsAndPTsSelector = createSelector(
  breakdownSubdomainSelector,
  (subdomain: IBookingManagerBreakdownSubdomain) => subdomain.shouldUserNeedToConfirmCPsAndPTs
);

export const breakdownTransfersSuppliersSelector = createSelector(
  breakdownSubdomainSelector,
  (subdomain: IBookingManagerBreakdownSubdomain) => subdomain.transfersSuppliers
);

export const breakdownGroundServicesSuppliersSelector = createSelector(
  breakdownSubdomainSelector,
  (subdomain: IBookingManagerBreakdownSubdomain) => subdomain.groundServicesSuppliers
);

export const breakdownActivitiesSuppliersSelector = createSelector(
  breakdownSubdomainSelector,
  (subdomain: IBookingManagerBreakdownSubdomain) => subdomain.activitiesSuppliers
);

export const currentlyEditingLineItemSelector = createSelector(
  breakdownSubdomainSelector,
  (subdomain: IBookingManagerBreakdownSubdomain) => subdomain.currentlyEditingLineItem
);

export const currentlyEditingLineItemIndexSelector = createSelector(
  breakdownSubdomainSelector,
  (subdomain: IBookingManagerBreakdownSubdomain) => subdomain.currentlyEditingLineItemIndex
);

export const BAMErrorSelector = createSelector(
  breakdownSubdomainSelector,
  (subdomain: IBookingManagerBreakdownSubdomain) => subdomain.accommodation.error
);

export const BAMHotelsByCurrencySelector = createSelector(
  breakdownSubdomainSelector,
  (subdomain: IBookingManagerBreakdownSubdomain) => subdomain.accommodation.data.filterHotels
);

export const BAMSearchAccommodationsRequestSelector = createSelector(
  breakdownSubdomainSelector,
  (subdomain: IBookingManagerBreakdownSubdomain) => subdomain.accommodation.requests.searchAccommodationsRequest
);

export const BAMFilterHotelsRequestSelector = createSelector(
  breakdownSubdomainSelector,
  (subdomain: IBookingManagerBreakdownSubdomain) => subdomain.accommodation.requests.filterHotelsRequest
);

export const BAMGuestAgesFilterSelector = createSelector(
  breakdownSubdomainSelector,
  (subdomain: IBookingManagerBreakdownSubdomain) => subdomain.accommodation.filters.guestAges
);

export const BAMHotelFilterSelector = createSelector(
  breakdownSubdomainSelector,
  (subdomain: IBookingManagerBreakdownSubdomain) => subdomain.accommodation.filters.hotelUuid
);

export const BAMStartDateFilterSelector = createSelector(
  breakdownSubdomainSelector,
  (subdomain: IBookingManagerBreakdownSubdomain) => subdomain.accommodation.filters.startDate
);

export const BAMEndDateFilterSelector = createSelector(
  breakdownSubdomainSelector,
  (subdomain: IBookingManagerBreakdownSubdomain) => subdomain.accommodation.filters.endDate
);

export const BAMAccommodationsSelector = createSelector(
  breakdownSubdomainSelector,
  (subdomain: IBookingManagerBreakdownSubdomain) => subdomain.accommodation.data.accommodationResults
);

export const BAMSelectedBuildSelector = createSelector(
  breakdownSubdomainSelector,
  (subdomain: IBookingManagerBreakdownSubdomain) => subdomain.accommodation.data.selectedBuild
);

export const exchangeCurrencyCodeSelector = createSelector(
  breakdownSubdomainSelector,
  (subdomain: IBookingManagerBreakdownSubdomain) => subdomain.headlineLineItemBreakdown?.ExchangeRate?.currency
);

export const exchangeCurrencyRateSelector = createSelector(
  breakdownSubdomainSelector,
  (subdomain: IBookingManagerBreakdownSubdomain) => subdomain.headlineLineItemBreakdown?.ExchangeRate?.rate
);

export const exchangeCurrencyRequestSelector = createSelector(
  breakdownSubdomainSelector,
  (subdomain: IBookingManagerBreakdownSubdomain) => subdomain.networkRequests.exchangeCurrencyRequest
);

export const BAMLastRequestSelector = createSelector(
  breakdownSubdomainSelector,
  (subdomain: IBookingManagerBreakdownSubdomain) => subdomain.accommodation.data.lastRequest
);

export const BAMLastRequestedBuildSelector = createSelector(
  breakdownSubdomainSelector,
  (subdomain: IBookingManagerBreakdownSubdomain) => subdomain.accommodation.data.lastRequestedBuild
);

export const BAMCountryDataSelector = createSelector(
  breakdownSubdomainSelector,
  (subdomain: IBookingManagerBreakdownSubdomain) => subdomain.accommodation.data.countryData
);

export const BAMOwnershipDataSelector = createSelector(
  breakdownSubdomainSelector,
  (subdomain: IBookingManagerBreakdownSubdomain) => subdomain.accommodation.data.ownershipData
);

export const BAMBookingBuildRequestSelector = createSelector(
  breakdownSubdomainSelector,
  (subdomain: IBookingManagerBreakdownSubdomain) => subdomain.accommodation.requests.bookingBuildRequest
);

export const BAMPageSelector = createSelector(
  breakdownSubdomainSelector,
  (subdomain: IBookingManagerBreakdownSubdomain) => subdomain.accommodation.page
);

export const BAMSelectedMealPlansSelector = createSelector(
  breakdownSubdomainSelector,
  (subdomain: IBookingManagerBreakdownSubdomain) => subdomain.accommodation.data.selectedMealPlan
);

export const BAMSelectedOccasionsSelector = createSelector(
  breakdownSubdomainSelector,
  (subdomain: IBookingManagerBreakdownSubdomain) => subdomain.accommodation.data.selectedOccasions
);

export const BAMSelectedRepeatGuestSelector = createSelector(
  breakdownSubdomainSelector,
  (subdomain: IBookingManagerBreakdownSubdomain) => subdomain.accommodation.data.isRepeatGuest
);

export const BAMSelectedTransfersSelector = createSelector(
  breakdownSubdomainSelector,
  (subdomain: IBookingManagerBreakdownSubdomain) => subdomain.accommodation.pageAddons.selectedTransfers
);

export const BAMSelectedGroundServicesSelector = createSelector(
  breakdownSubdomainSelector,
  (subdomain: IBookingManagerBreakdownSubdomain) => subdomain.accommodation.pageAddons.selectedGroundServices
);

export const BAMSelectedOtherItemsSelector = createSelector(
  breakdownSubdomainSelector,
  (subdomain: IBookingManagerBreakdownSubdomain) => subdomain.accommodation.pageAddons.selectedOtherItems
);

export const BAMSelectedFinesSelector = createSelector(
  breakdownSubdomainSelector,
  (subdomain: IBookingManagerBreakdownSubdomain) => subdomain.accommodation.pageAddons.selectedFines
);

export const exchangeCurrencyTimeStampSelector = createSelector(
  breakdownSubdomainSelector,
  (subdomain: IBookingManagerBreakdownSubdomain) => subdomain.headlineLineItemBreakdown?.ExchangeRate?.timestamp
);

export const editAccommodationFormSelector = createSelector(
  breakdownSubdomainSelector,
  (subdomain: IBookingManagerBreakdownSubdomain) => subdomain.editAccommodationForm
);

export const editAccommodationGuestAgesSelector = createSelector(
  breakdownSubdomainSelector,
  (subdomain: IBookingManagerBreakdownSubdomain) => subdomain.editAccommodationForm?.guestAges || null
);

export const editAccommodationStartDateSelector = createSelector(
  breakdownSubdomainSelector,
  (subdomain: IBookingManagerBreakdownSubdomain) => subdomain.editAccommodationForm?.startDate || ''
);

export const editAccommodationEndDateSelector = createSelector(
  breakdownSubdomainSelector,
  (subdomain: IBookingManagerBreakdownSubdomain) => subdomain.editAccommodationForm?.endDate || ''
);

export const editAccommodationDateRangeSelector = createSelector(
  editAccommodationFormSelector,
  (form): IDateRange | null => {
    if (!form) {
      return null;
    }
    const end = addDays(new Date(form.endDate), 1);
    
    return { start: form.startDate, end: formatDateWithoutOffset(end) };
  }
);


export const editAccommodationTotalStayNightsSelector = createSelector(editAccommodationDateRangeSelector, (dateRange): number | null => {
  if (!dateRange) {
    return null;
  }
  if (!dateRange.end) {
    return 0;
  }
  return differenceInCalendarDays(new Date(dateRange.end), new Date(dateRange.start));
});

export const editAccommodationTotalStayDaysSelector = createSelector(editAccommodationTotalStayNightsSelector, (days): number | null => {
  if (!days) {
    return null;
  }
  return days + 1;
});

export const editAccommodationSelectedDatesSelector = createSelector(
  editAccommodationDateRangeSelector,
  editAccommodationTotalStayDaysSelector,
  (dateRange, totalStayDays): string[] => {
    if (!dateRange || !totalStayDays) {
      return [];
    }
    const firstTimestamp = new Date(dateRange.start).getTime();
    return DateHelper.generateDatesFrom(firstTimestamp, totalStayDays, 'en-US').map(d => d.dateString);
  }
);

export const editAccommodationSelectedMealPlanSelector = createSelector(
  breakdownSubdomainSelector,
  (subdomain: IBookingManagerBreakdownSubdomain) => subdomain.editAccommodationForm?.selectedMealPlan
);

export const editAccommodationOccasionsSelector = createSelector(
  breakdownSubdomainSelector,
  (subdomain: IBookingManagerBreakdownSubdomain) => subdomain.editAccommodationForm?.selectedOccasions || []
);

export const editAccommodationIsRepeatGuestSelector = createSelector(
  breakdownSubdomainSelector,
  (subdomain: IBookingManagerBreakdownSubdomain) => subdomain.editAccommodationForm?.isRepeatGuest
);

export const editAccommodationAccommodationDetailsSelector = createSelector(
  breakdownSubdomainSelector,
  (subdomain: IBookingManagerBreakdownSubdomain) => subdomain.editAccommodationForm?.accommodationDetails
);

export const editAccommodationSelectedBuildSelector = createSelector(
  breakdownSubdomainSelector,
  (subdomain: IBookingManagerBreakdownSubdomain) => subdomain.editAccommodationForm?.selectedBuild
);

export const editAccommodationBookingBuildRequestSelector = createSelector(
  breakdownSubdomainSelector,
  (subdomain: IBookingManagerBreakdownSubdomain) => subdomain.editAccommodationForm?.requests.bookingBuildRequest
);

export const editAccommodationInitiateBookingBuildRequestSelector = createSelector(
  breakdownSubdomainSelector,
  (subdomain: IBookingManagerBreakdownSubdomain) => subdomain.editAccommodationForm?.requests.initiateBookingBuildRequest
);

export const editAccommodationOwnershipDataSelector = createSelector(
  breakdownSubdomainSelector,
  (subdomain: IBookingManagerBreakdownSubdomain) => subdomain.editAccommodationForm?.ownershipData
);

export const editAccommodationCountryDataSelector = createSelector(
  breakdownSubdomainSelector,
  (subdomain: IBookingManagerBreakdownSubdomain) => subdomain.editAccommodationForm?.countryData
);

export const editAccommodationLastRequestedBuildSelector = createSelector(
  breakdownSubdomainSelector,
  (subdomain: IBookingManagerBreakdownSubdomain) => subdomain.editAccommodationForm?.lastRequestedBuild
);

export const editAccommodationAccommodationIndexSelector = createSelector(
  breakdownSubdomainSelector,
  (subdomain: IBookingManagerBreakdownSubdomain) => subdomain.editAccommodationForm?.accomIndex
);

export const editAccommodationSimulateBreakdownRequestSelector = createSelector(
  breakdownSubdomainSelector,
  (subdomain: IBookingManagerBreakdownSubdomain) => subdomain.editAccommodationForm?.requests.simulateBreakdownRequest
);

export const editAccommodationErrorsSelector = createSelector(
  breakdownSubdomainSelector,
  (subdomain: IBookingManagerBreakdownSubdomain) => subdomain.editAccommodationForm?.errors
);

export const editAccommodationHasEditedSelector = createSelector(
  breakdownSubdomainSelector,
  (subdomain: IBookingManagerBreakdownSubdomain) => subdomain.editAccommodationForm?.hasEdited
);