import React, { useCallback, useEffect, useState } from 'react';
import { theme } from '../../../tailwind.config';
import { ArrowDownComponent as ArrowDownIcon } from 'ui/Icons';
import classnames from 'classnames';
import { useHideOnClickOutside } from 'hooks/useHideOnClickOutside';

export interface ISingleSelectOption {
  label?: string;
  value?: string;
  renderItem?: (className?: string) => React.ReactElement;
}

export enum ESingleSelectDirection {
  UP = 'up',
  DOWN = 'down',
}

export interface ISingleSelectProps {
  fieldId: string;
  value?: string;
  options: ISingleSelectOption[];
  onChange?: (value?: string) => void;
  label?: string;
  maxVisibleItems?: number;
  direction?: ESingleSelectDirection;
  labelWhenNothingSelected?: string;
  useCustomLabelWhenNothingSelected?: boolean;
  className?: string;
  inputClassName?: string;
  labelClassName?: string;
  showSelectedOption?: boolean;
  scrollToSelectedItem?: boolean;
  loading?: boolean;
  notifyOpenStatus?: (isOpen: boolean) => void;
  hasLegacyLook?: boolean;
  errorMessage?: string | null;
  errorClassName?: string;
  disabled?: boolean;
}

const scrollToElement = (instanceClassname: string, value?: string): void => {
  const itemsContainer = document.querySelector(`.${instanceClassname} .options-container`);
  const selectedItemContainer: HTMLElement | null = document.querySelector(
    `.${instanceClassname} .options-container .value-${value}`
  );

  if (itemsContainer && selectedItemContainer) {
    itemsContainer.scrollTop = Math.max(selectedItemContainer.offsetTop - 35, 0);
  }
};

const SingleSelect: React.FC<ISingleSelectProps> = React.memo(
  ({
    fieldId,
    value,
    options,
    onChange,
    label,
    maxVisibleItems,
    direction = ESingleSelectDirection.DOWN,
    labelWhenNothingSelected,
    useCustomLabelWhenNothingSelected = false,
    scrollToSelectedItem = false,
    className = '',
    inputClassName = '',
    labelClassName = '',
    showSelectedOption = true,
    loading = false,
    notifyOpenStatus,
    hasLegacyLook = false,
    errorMessage,
    errorClassName,
    disabled = false,
  }) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const optionsHeight = maxVisibleItems ? { style: { maxHeight: `${maxVisibleItems * 35}px` } } : {};

    const notifyOnClickOutside = useCallback(() => {
      notifyOpenStatus && notifyOpenStatus(false);
    }, [notifyOpenStatus]);
    const { instanceClassname } = useHideOnClickOutside(setIsOpen, 'select', notifyOnClickOutside);

    const selectedOption = options.find(opt => opt.value === value);
    const optionsWithoutSelectedOne = options.filter(opt => opt.value !== value);
    const optionsWhenSomethingSelected = showSelectedOption ? options : optionsWithoutSelectedOne;
    const innerOptions: ISingleSelectOption[] =
      labelWhenNothingSelected && !!value
        ? [
            {
              label: labelWhenNothingSelected,
              value: undefined,
            },
            ...optionsWhenSomethingSelected,
          ]
        : optionsWhenSomethingSelected;

    useEffect(() => {
      isOpen && scrollToSelectedItem && scrollToElement(instanceClassname, selectedOption?.value);
    }, [isOpen, instanceClassname, scrollToSelectedItem, selectedOption]);

    const toggle = useCallback(() => {
      !disabled && setIsOpen(!isOpen);
      notifyOpenStatus && notifyOpenStatus(!isOpen);
    }, [isOpen, notifyOpenStatus, disabled]);

    const select = useCallback(
      optionId => {
        onChange && onChange(optionId);
      },
      [onChange]
    );

    const renderSelectedOption = (selectedOption?: ISingleSelectOption) => {
      if (selectedOption?.renderItem) {
        return (
          <div className="px-10px" style={{ width: 'calc(100% - 45px)' }}>
            {selectedOption?.renderItem()}
          </div>
        );
      }
      return (
        <span
          className={classnames({
            'font-pt-sans px-10px py-2 text-15px leading-19px whitespace-nowrap select-none flex overflow-hidden text-ellipsis': true,
            'text-gray-100': disabled,
            'text-black': !disabled,
          })}
          style={{ maxWidth: 'calc(100% - 45px)' }}
        >
          {selectedOption?.label}
        </span>
      );
    };

    return (
      <div
        onKeyDown={e => {
          if (e.keyCode === 32 || e.keyCode === 13) {
            // if the key is 'Space' or 'Enter' toggle the dropdown menu
            e.stopPropagation();
            e.preventDefault();
            toggle();
          }
        }}
        tabIndex={0}
        className={classnames(
          instanceClassname,
          'flex flex-col min-w-75px focus-visible:outline-none focus-visible:shadow-shadow-marine',
          className
        )}
      >
        {label && (
          <label
            htmlFor={fieldId}
            className={classnames(
              labelClassName,
              'font-pt-sans mb-5px default:text-black default:text-13px default:leading-17px default:tracking-2xs default:select-none'
            )}
          >
            {label}
          </label>
        )}
        <div
          id={fieldId}
          className={classnames(
            'relative flex w-full cursor-pointer items-center justify-between text-15px h-[35px]',
            inputClassName,
            {
              'border border-solid': !disabled,
              'border-gray-40': !errorMessage,
              'border-red-95 bg-red-25': !!errorMessage,
              'bg-white': !errorMessage && hasLegacyLook,
              'bg-ivory': !errorMessage && !hasLegacyLook && !disabled,
              'bg-gray-10': disabled,
            }
          )}
          onClick={toggle}
        >
          <div className="options flex items-center justify-start relative h-[33px] w-full">
            {!value && !loading && (
              <span
                className={classnames('font-pt-sans px-10px py-2 select-none text-15px leading-19px', {
                  'text-black': useCustomLabelWhenNothingSelected,
                  'text-gray-100': !useCustomLabelWhenNothingSelected,
                })}
              >
                {!useCustomLabelWhenNothingSelected ? 'Select ...' : labelWhenNothingSelected}
              </span>
            )}
            {value && !loading && renderSelectedOption(selectedOption)}
            {loading && (
              <div className="flex justify-center items-center min-h-35px" style={{ width: 'calc(100% - 30px)' }}>
                <i className="text-lg fas fa-circle-notch fa-spin text-gray-80"></i>
              </div>
            )}

            <div
              className={classnames('font-pt-sans absolute -left-1px z-30 w-full border border-solid border-gray-20 ', {
                hidden: !isOpen,
                'bg-ivory': !hasLegacyLook,
                'bg-white': hasLegacyLook,
                'top-[35px] border-t-0': direction === ESingleSelectDirection.DOWN,
                'bottom-[34px] border-b-0': direction === ESingleSelectDirection.UP,
              })}
              style={{ width: 'calc(100% + 2px)' }}
            >
              <div className="options-container flex flex-col overflow-y-auto select-none" {...optionsHeight}>
                {!loading &&
                  innerOptions.map(option => (
                    <span
                      key={`${option.value} ${option.label}`}
                      className={classnames(`value-${option.value} flex items-center hover:bg-teal-40 px-10px`)}
                      onClick={() => select(option.value)}
                    >
                      {option.renderItem ? (
                        option.renderItem(classnames({ 'font-bold': option.value === value }))
                      ) : (
                        <span
                          className={classnames('block font-pt-sans py-2 text-15px leading-19px', {
                            'font-bold': option.value === value,
                          })}
                        >
                          {option.label}
                        </span>
                      )}
                    </span>
                  ))}
              </div>
            </div>
          </div>

          <span className="absolute top-0 right-0 border-l border-solid border-l-gray-20 flex h-full w-35px items-center justify-center">
            <ArrowDownIcon
              icon={theme.colors['gray-80']}
              className={classnames('transition-rotate duration-500 ease-in-out', 'fill-gray-80', {
                'rotate-180': isOpen,
                'rotate-0': !isOpen,
              })}
            />
          </span>
        </div>

        {errorMessage && (
          <p className={`text-red-95 text-13px leading-2xs font-pt-sans mt-5px mb-0 ${errorClassName ?? ''}`}>
            {errorMessage}
          </p>
        )}
      </div>
    );
  }
);

export default SingleSelect;
