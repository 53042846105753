import { useMutation } from "@tanstack/react-query";
import { makeCRMApi } from "../CrmApi";
import { INewCompanyRequest } from "../types/CrmCompanyTypes";

export const useCreateCompanyMutation = () => {
  const crmApi = makeCRMApi();
  
  const createCompanyMutation = useMutation({
    mutationKey: ['crm-create-company'],
    mutationFn: (requestData: INewCompanyRequest) => crmApi.createCompany(requestData),
  });
  
  return createCompanyMutation;
}
