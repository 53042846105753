import React, { useCallback } from 'react';
import classnames from 'classnames';

import { GeneralModal } from 'ui/GeneralModal';
import { useCurrentWidth } from 'effects';
import { CloseXComponent as CloseXIcon } from 'ui/Icons';
import { LoadingBar } from 'ui/NetworkStatusBar';
import { ButtonWithIcon } from './components/ButtonWithIcon';
import { useDispatch } from 'react-redux';
import { enqueueNotification } from 'store/modules/ui';
import WhatsappLinkIcon from 'ui/Icons/components/Whatsapp.component';
import EmailLinkIcon from 'ui/Icons/components/EmailLink.component';
import LinkIcon from 'ui/Icons/components/Link.component';

export interface iBasketShareLinkModalProps {
  shareLink: string | null;
  isLoading: boolean;
  onClose?: () => void;
}

export const BasketShareLinkModal = (props: iBasketShareLinkModalProps) => {
  const {
    shareLink,
    isLoading,
    onClose,
  } = props;
  const dispatch = useDispatch();
  const bodyMessage = `Thanks for your enquiry. Please find details of the options available together with prices and additional information below. If you would like to proceed or need further information please feel free to ask me any questions: ${shareLink}`;

  const { isMobile, isMobileLandscape, isTablet, isDesktop, isDesktopHD } = useCurrentWidth();
  const modalWidthClassname = classnames('flex justify-center', {
    'max-w-330px': isMobile || isMobileLandscape,
    'max-w-480px': isTablet || isDesktop || isDesktopHD,
    'min-h-270px': !isLoading,
  });
  const proposalModalContentClassname = classnames('proposal-modal-content flex flex-col flex-1');

  const handleEmail = useCallback(async () => {
    // Open email
    const subject = `Enquiry - Options, Availability and Costs.`;
    window.open(`mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(bodyMessage)}`, '_blank');
  }, [bodyMessage]);
  const handleWhatsAppRedirect = useCallback(async () => {
    // Redirect to whatsapp page
    window.open(`https://wa.me?text=${bodyMessage}`, '_blank');
  }, [bodyMessage]);
  const handleCopyLink = useCallback(async () => {
    try {
      await navigator.clipboard.writeText(shareLink || '');
      dispatch(
        enqueueNotification({
          message: `Link copied successfully`,
          options: { variant: 'success' },
        })
      );
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('e', e);
      dispatch(
        enqueueNotification({
          message: `Failed to copy link`,
          options: { variant: 'error' },
        })
      );
    }
  }, [dispatch, shareLink]);

  return (
    <GeneralModal
      modalWindowClassName={modalWidthClassname}
      shouldCloseByClickingOutside
      isCloseButtonVisible={false}
    >
      <div className={classnames('proposal-modal flex justify-center p-10 w-screen', modalWidthClassname)}>
        <div className={proposalModalContentClassname}>
          <div className="proposal-modal-title flex items-center justify-between">
            <p className="proposal-modal-title text-black text-21px leading-27px font-noe-display mt-5px my-0">
              Share Items
            </p>
            <div
              className="modal-close-button bg-ivory w-14px h-14px flex justify-center items-center cursor-pointer"
              onClick={onClose}
            >
              <CloseXIcon />
            </div>
          </div>

          {isLoading && (
            <span className="modal-loading mt-5 flex justify-center">
              <LoadingBar />
            </span>
          )}
          <label className="w-full mt-5 mb-5">
            <span className="block mb-3 text-gray-100 text-12px font-hurmegeometric-sans">
              URL
            </span>
            <input
              type="text"
              className="share-link-input p-3 gray-100 border border-solid border-gray-40 text-gray-100 min-h-40px w-full text-14px font-hurmegeometric-sans"
              value={shareLink || ''}
              disabled={true}
              readOnly={true}
            />
          </label>          
          <div className="save-quote-proposal-buttons flex gap-[8px]">
            <ButtonWithIcon
              caption="WHATSAPP"
              Icon={WhatsappLinkIcon}
              className="booking-button-save"
              wrapperClassName="flex items-center gap-[5px]"
              iconClassName="relative top-[2px]"
              onClick={handleWhatsAppRedirect}
              disabled={false}
            />
            <ButtonWithIcon
              caption="E-MAIL"
              Icon={EmailLinkIcon}
              className="email-link-button"
              wrapperClassName="flex items-center gap-[5px]"
              iconClassName="relative top-[2px]"
              onClick={handleEmail}
              disabled={false}
            />
            <ButtonWithIcon
              caption="COPY LINK"
              Icon={LinkIcon}
              className="copy-link-button"
              wrapperClassName="flex items-center gap-[5px] text-13px"
              iconClassName="relative top-[0px]"
              onClick={handleCopyLink}
              disabled={false}
            />
          </div>
        </div>
      </div>
    </GeneralModal>
  );
};
