import { pipe, values, reverse, ifElse, always, length, equals, all, complement, both, path } from 'ramda';
import { isNilOrEmpty } from 'ramda-adjunct';
import {
  differenceInCalendarDays,
  eachDayOfInterval,
  format,
  subDays,
  addDays,
  differenceInDays,
  addHours,
  formatDistanceToNow,
} from 'date-fns';

import config from '../config';
import { formatInTimeZone } from 'date-fns-tz';

export const SECONDS = 1000;

export type tDateString = `${number}${number}${number}${number}-${number}${number}-${number}${number}`;

/**
 * To date
 *
 * Returns a new Date object
 *
 * @param {string | Date} date
 * @returns {Date}
 */
export const toDate = date => (date ? new Date(date) : new Date());

/**
 * Returns correct object length
 *
 * @param {object}
 * @returns {boolean}
 */
const correctLength = pipe(length, equals(2));

/**
 * Ensure all keys are populate
 *
 * @param {*}
 * @returns {boolean}
 */
const allPopulated = all(complement(isNilOrEmpty));

/**
 * Returns difference bewteen days
 *
 * @param {array} dates
 * @returns {*}
 */
// @ts-ignore todo LEGACY converted from date.js
const getDifference = dates => differenceInCalendarDays(...dates);

/**
 * Check both to and from exist
 *
 * @param {object}
 * @returns {boolean}
 */
const hasToFrom = both(correctLength, allPopulated);

/**
 * Get the number of days (nights) bewteen dates
 *
 * @param {object}
 * @returns {number | undefined}
 */
// @ts-ignore todo LEGACY converted from date.js
export const getNumberOfDays = pipe(values, reverse, ifElse(hasToFrom, getDifference, always(undefined)));

export const offsetDate = date => {
  const d = new Date(date);
  return addHours(new Date(d.getTime() + d.getTimezoneOffset() * 60 * 1000), 2);
};

/**
 * Date formatter
 *
 * Wrapper around date-fns date formatter
 *
 * @param {string | Date | number} date
 * @param {string} pattern
 */
export const formatDate = (date, pattern = path(['defaults', 'dateFormat'], config)) => {
  // format already takes locale into account, offset function is a wrong concept,
  // changing the timestamp in date is wrong, 9:00am UK IS the same timestamp as 11:00am in Spain
  // not changing it just in case it impacts elsewhere
  // @ts-ignore todo LEGACY converted from date.js
  return format(offsetDate(date), pattern);
};
export const formatDateWithoutOffset = (date, pattern = path(['defaults', 'dateFormat'], config)) => {
  // @ts-ignore todo LEGACY converted from date.js
  return format(new Date(date), pattern);
};

export const addDaysUTC = (date, amount) => addDays(new Date(date), amount);
export const subDaysUTC = (date, amount) => subDays(new Date(date), amount);

export const formatDateDisplay = date => formatDate(date, path(['defaults', 'displayDateFormat'], config));
export const formatDateDisplayWithoutOffset = date =>
  formatDateWithoutOffset(date, path(['defaults', 'displayDateFormat'], config));
export const formatDateDisplayFourDigitYear = date => formatDate(date, 'dd MMM yyyy');
export const formatDateTimeDisplay = date => formatDate(date, path(['defaults', 'displayDateTimeFormat'], config));
export const formatDateTimeDisplayWithoutOffset = date =>
  formatDateWithoutOffset(date, path(['defaults', 'displayDateTimeFormat'], config));
export const formatCreditNoteDateDisplay = date =>
  formatDate(date, path(['defaults', 'displayCreditNoteDateFormat'], config));

export const formatDateTimeIncludeTimezoneDisplay = date =>
  formatDate(date, path(['defaults', 'displayDateTimeIncludeTimezoneFormat'], config));

export const formatDateRangeDisplay = (startDate, endDate, formatDateFn = formatDateDisplay) => {
  if (!endDate) {
    return startDate ? formatDateFn(startDate) : null;
  }

  if (startDate === endDate) {
    return formatDateFn(startDate);
  }

  return `${formatDateFn(startDate)} - ${formatDateFn(endDate)}`;
};

export const generateArrayOfDatesBetween = (startDate, endDate) => {
  return eachDayOfInterval({
    start: offsetDate(startDate),
    end: offsetDate(endDate),
    // @ts-ignore todo LEGACY converted from date.js
  }).map(date => format(date, path(['defaults', 'dateFormat'], config)));
};

export const generateArrayOfDatesBetweenWithoutOffset = (startDate, endDate) => {
  return eachDayOfInterval({
    start: new Date(startDate),
    end: new Date(endDate),
    // @ts-ignore todo LEGACY converted from date.js
  }).map(date => format(date, path(['defaults', 'dateFormat'], config)));
};

export const dateToMidnightDate = date => {
  return new Date(formatDate(date));
};

export const numberOfNights = (arrivalDate, departureDate) => {
  if (!arrivalDate || !departureDate) {
    return '';
  }
  return differenceInDays(offsetDate(departureDate), offsetDate(arrivalDate));
};

/**
 * each date range is an object with a `start` and `end` date
 * the dates are in `yyyy-MM-dd` format
 */
export const getOverlappingDates = dateRanges => {
  const overlappingDatesSet = new Set();
  const sortedRanges = [...dateRanges].sort((a, b) => a.start.localeCompare(b.start));

  for (let i = 0; i < sortedRanges.length - 1; i++) {
    const current = sortedRanges[i];

    for (let j = i + 1; j < sortedRanges.length; j++) {
      const next = sortedRanges[j];

      if (next.start <= current.end) {
        const overlapStart = new Date(next.start);
        const overlapEnd = new Date(current.end < next.end ? current.end : next.end);

        let currentDate = new Date(overlapStart);
        while (currentDate <= overlapEnd) {
          overlappingDatesSet.add(currentDate.toISOString().split('T')[0]);
          currentDate.setDate(currentDate.getDate() + 1);
        }
      }
    }
  }

  return Array.from(overlappingDatesSet).sort();
};

const newDateUTC = dateString => {
  const [year, month, day] = dateString.split('-').map(Number);
  return new Date(Date.UTC(year, month - 1, day));
};

export const _subDays = (date: tDateString, daysToSubtract: number): tDateString => {
  // convert the date to utc, subtract the days, then format it to UTC time
  return formatInTimeZone(subDays(newDateUTC(date), daysToSubtract), 'UTC', 'yyyy-MM-dd') as tDateString;
};

export const parseDuration = (date1: Date, date2: Date) => {
  const milliseconds = Math.abs(date1.getTime() - date2.getTime());
  // Handle negative values
  
  // Calculate each unit
  const days = Math.floor(milliseconds / (24 * 60 * 60 * 1000));
  const remainingAfterDays = milliseconds % (24 * 60 * 60 * 1000);
  
  const hours = Math.floor(remainingAfterDays / (60 * 60 * 1000));
  const remainingAfterHours = remainingAfterDays % (60 * 60 * 1000);
  
  const minutes = Math.floor(remainingAfterHours / (60 * 1000));
  const remainingAfterMinutes = remainingAfterHours % (60 * 1000);
  
  const seconds = Math.floor(remainingAfterMinutes / 1000);
  const millisecondsPart = remainingAfterMinutes % 1000;

  return {
    days,
    hours,
    minutes,
    seconds,
    milliseconds: millisecondsPart
  };
}

export const _format = (date: tDateString, formatString: string = 'yyyy-MM-dd'): tDateString => {
  // convert the date to utc, subtract the days, then format it to UTC time
  return formatInTimeZone(newDateUTC(date), 'UTC', formatString) as tDateString;
};

/**
 * calculates the relative time difference between the given datetime and now
 * this function calculates WITH timezones taken into account 
 * if the distance is more than 1 day, will return a string like "on 21 Nov at 11AM"
 * if the distance is less than 1 day, will return a string like "in 5h and 10m"
 */
export const formatExpiresTimeSentence = (datetime: string): {
  prefix: string;
  expiresString: string
} => {
  const expiryDateObject = new Date(datetime);
  const nowDateObject = new Date();

  const distance = parseDuration(expiryDateObject, nowDateObject);

  if (distance.days <= 0) {
    return {
      prefix: 'in',
      expiresString: `${distance.hours}h and ${distance.minutes}m`
    }
  }

  return {
    prefix: 'on',
    expiresString: format(expiryDateObject, 'd MMM') + ' at ' + format(expiryDateObject, 'ha')
  }
};