import React from 'react';
import classnames from 'classnames';

interface IProgressBarProps {
  total: number;
  score: number;
}

export const ProgressBar: React.FC<IProgressBarProps> = React.memo(
  ({ score, total }) => {
    const commonClass = "rounded-[10px]";
    const outerClass = classnames(commonClass, 'outer-bar min-h-[10px] max-h-[10px] bg-gray-10 flex items-center px-[4px]');
    const innerClass = classnames(commonClass, 'inner-bar min-h-[4px] max-h-[4px] bg-teal-100');
    const normalizedScore = score < 0 ? 0 : score;
    const normalizedTotal = total < 0 ? 0 : total;
    const percentage = Math.floor(normalizedScore / normalizedTotal * 100) || 0;

    return (
      <div className={outerClass}>
        <div className={innerClass} style={{ width: `${percentage}%` }}>
        </div>
      </div>
    );
  }
);
