import { IPage, ISort, IUser } from 'services/BackendApi';
import { IBootstrapCountry } from 'services/BackendApi/types/country';
import { ESortOrder, IPagination } from 'store/common/types';

export enum ECrmDirectoryEntryType {
  COMPANY = 'COMPANY',
  PERSON = 'PERSON',
}

export enum ECrmDirectoryListSortableKey {
  NAME = 'name',
  PROFILE_TYPE = 'profileType',
  MANAGER = 'manager',
  COUNTRY = 'country',
  STAGE = 'leadStage',
  SCORE = 'leadScore',
  CREATED_AT = 'createdAt',
}

export interface IDirectorySearchParams {
  pagination: IPage;
  sort: ISort<ECrmDirectoryListSortableKey>;
}

export interface IDirectoryEntry {
  directoryEntryType: ECrmDirectoryEntryType;
  profileId: string;
  profileType: {
      code: string;
      value: string;
  }
  name: string;
  country: IBootstrapCountry;
  leadStage: {
      code: string;
      value: string;
      order: number;
  }
  leadScore: number;
  manager: {
      id: string;
      email: string;
      firstName: string;
      lastName: string;
      type: string;
  }
  createdAt: string;
}

export interface IDirectorySearchResponse {
  list: IDirectoryEntry[];
  pagination: IPagination;
  count: number;
}




