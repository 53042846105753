import { useMutation } from "@tanstack/react-query";
import { makeCRMApi } from "../CrmApi";
import { INewContactRequest } from "../types/CrmContactTypes";

export const useCreateContactMutation = () => {
  const crmApi = makeCRMApi();
  
  const createContactMutation = useMutation({
    mutationKey: ['crm-create-contact'],
    mutationFn: (requestData: INewContactRequest) => crmApi.createContact(requestData),
  });
  
  return createContactMutation;
}
