import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { makeCRMApi } from "../CrmApi";
import { AxiosError, AxiosResponse } from "axios";
import { IPaginationFE, ISorting } from "store/common/types";
import { ECrmDirectoryListSortableKey, IDirectorySearchResponse } from "../types/DirectoryEntry";

export interface IUseCrmDirectoryEntriesParams {
  pagination: IPaginationFE;
  sorting: ISorting<ECrmDirectoryListSortableKey>;
}
export const useCrmDirectoryEntries = (params: IUseCrmDirectoryEntriesParams) => {
  const crmApi = makeCRMApi();
  
  const crmDirectoryEntries = useQuery<AxiosResponse<IDirectorySearchResponse>, AxiosError>({
    queryKey: ['crm-directory-entries', params.sorting.sortBy, params.sorting.sortOrder, params.pagination.currentPage, params.pagination.itemsPerPage],
    placeholderData: keepPreviousData,
    queryFn: () =>
      crmApi.postDirectorySearch({
      sort: {
        key: params.sorting.sortBy,
        order: params.sorting.sortOrder,
      },
      pagination: {
        limit: params.pagination.itemsPerPage,
        offset: params.pagination.currentPage * params.pagination.itemsPerPage,
      }
    }),
  });
  
  return { 
    directoryEntries: crmDirectoryEntries.data?.data?.list ?? null,
    directoryEntriesCount: crmDirectoryEntries.data?.data?.count ?? null,
    crmDirectoryEntries,
    isDirectoryEntriesPending: crmDirectoryEntries.isPending,
   };
}
