import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { PageTitle } from 'ui/PageTitle';
import { TaskFilters } from './TaskFilters';
import { deleteTaskRequestAction, getSelectedTaskRequestAction, getTaskListRequestAction, resetFiltersAction, setItemsPerPageAction, setPageNumberAction, setSortAction } from 'store/modules/taskManagement/actions';
import { currentPageSelector, getTasksCountSelector, getTasksSelector, taskSortBySelector, taskSortOrderSelector, taskitemsPerPageSelector, tasksLoadingSelector, tasksPageCountSelector } from 'store/modules/taskManagement/selectors';
import { Table } from 'ui/Table';
import { ITableColumn, ITableRow } from 'ui/Table/types';
import { formatDateDisplay, formatDateTimeDisplay, offsetDate } from 'utils';
import { ESortOrder } from 'store/common/types';
import { Pagination } from 'pureUi/Pagination';
import classnames from 'classnames';
import { PriorityHighComponent, edit } from 'ui/Icons';
import { Tooltip } from 'containers/TravelAgents/List/Tooltip';
import { CircleIconButton } from 'ui/CircleIconButton';
import { LoadingBar } from 'ui/NetworkStatusBar';
import { ETasksSortableKey, ETaskStatus } from 'services/TaskManagementApi/types/TaskManagementResponse';
import { TaskListStatus } from 'ui/TaskListStatus';
import { TaskListPriority } from './TaskListPriority';
import { useCurrentWidth } from 'effects';
import ConfirmationModal, { EConfirmationModalType } from 'ui/ConfirmationModal';
import { getCurrentUser, isAdmin as isAdminSelector } from 'store/modules/auth';
import { IUser } from 'services/BackendApi';
import { useHistory } from 'react-router';
import { getPageResultsText } from 'utils/string';

export const TaskList: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { currentWidth } = useCurrentWidth();
  const tasks = useSelector(getTasksSelector);
  const tasksPageCount = useSelector(tasksPageCountSelector);
  const currentPage = useSelector(currentPageSelector);
  const tasksCount = useSelector(getTasksCountSelector);
  const isInitialized = tasksCount !== null;
  const taskitemsPerPage = useSelector(taskitemsPerPageSelector);
  const tasksLoading = useSelector(tasksLoadingSelector);

  const taskSortBy = useSelector(taskSortBySelector);
  const taskSortOrder = useSelector(taskSortOrderSelector);
  const user = useSelector(getCurrentUser) as IUser;
  const isAdmin = useSelector(isAdminSelector);
  const [taskToDelete, setTaskToDelete] = useState<string | null>(null);

  const handlePageChange = useCallback((pageNumber: number) => {
    dispatch(setPageNumberAction(pageNumber - 1));
  }, []);

  const handleItemsPerPageChange = useCallback((itemsPerPage: number) => {
    dispatch(setItemsPerPageAction(itemsPerPage));
  }, []);

  const handleSort = useCallback(
    (newSortBy: ETasksSortableKey, newSortOrder: ESortOrder) => {
      dispatch(setSortAction(newSortBy, newSortOrder));
    },
    [dispatch]
  );

  const handleOpenConfirmationModal = useCallback(
    (taskId: string) => {
      setTaskToDelete(taskId);
    },
    [taskToDelete]
  );

  const handleConfirmConfirmationModal = useCallback(
    () => {
      if (taskToDelete) {
        dispatch(deleteTaskRequestAction(taskToDelete));
        setTaskToDelete(null);
      }
    },
    [taskToDelete]
  );

  const handleCancelConfirmationModal = useCallback(
    () => {
      setTaskToDelete(null);
    },
    [taskToDelete]
  );

  const handleEditTask = useCallback(
    (taskId: string) => {
      history.push(`/tasks/${taskId}/edit`)
    },
    [dispatch]
  );

  useEffect(() => {
    dispatch(getTaskListRequestAction());
  }, []);

  const columns: ITableColumn[] = useMemo(() => {
    return [
      { id: 'id', label: 'ID', width: '10px' },
      { id: 'subject', label: 'SUBJECT', width: '60px', className: 'text-ellipsis overflow-hidden', fieldToSortBy: ETasksSortableKey.SUBJECT },
      { id: 'category', label: 'CATEGORY', width: '20px', fieldToSortBy: ETasksSortableKey.CATEGORY, className: 'text-ellipsis overflow-hidden' },
      { id: 'status', label: 'STATUS', width: '20px', fieldToSortBy: ETasksSortableKey.STATUS },
      { id: 'team', label: 'TEAM', width: '20px', fieldToSortBy: ETasksSortableKey.TEAM, className: 'text-ellipsis overflow-hidden' },
      { id: 'assignee', label: 'ASSIGNEE', width: '20px', className: 'text-ellipsis overflow-hidden whitespace-nowrap', fieldToSortBy: ETasksSortableKey.ASSIGNEE },
      { id: 'priority', label: 'PRIORITY', width: '20px', fieldToSortBy: ETasksSortableKey.PRIORITY },
      { id: 'deadline', label: 'DEADLINE', width: '25px', fieldToSortBy: ETasksSortableKey.DEADLINE },
      { id: 'bookingRef', label: 'BOOKING REF', width: '30px', fieldToSortBy: ETasksSortableKey.BOOKING_HUMAN_READABLE_ID },
      { id: 'reporter', label: 'REPORTER', width: '30px', fieldToSortBy: ETasksSortableKey.REPORTER, isHidden: currentWidth < 1920 },
      { id: 'actions', label: '', width: '20px' }
    ];
  }, [tasks, currentWidth])

  const rows: ITableRow[] = useMemo(() => {
    return tasks.map(task => {
      const status = (<TaskListStatus status={task.status} />);
      const priority = (<TaskListPriority priority={task.priority} />);
      const isUrgent = task.status !== ETaskStatus.DONE && offsetDate(task.deadline) < new Date();      
      const deadline = (<div className={classnames('flex items-center', { 'text-status-cancelled': isUrgent })}>{currentWidth > 1440 ? formatDateTimeDisplay(task.deadline) : formatDateDisplay(task.deadline)} {isUrgent && <PriorityHighComponent className='w-4 h-4 fill-status-cancelled' />}</div>);
      const disableDelete = !isAdmin && task.reporter.id !== user.uuid;
      const actions = (
        <div className="item-actions flex items-center relative">
          <div className="w-4px h-34px rounded bg-brown-15 mr-10px" />
          <Tooltip content="Edit">
            <CircleIconButton
              type="secondary"
              className="edit-action mx-1"
              iconClass="inline relative left-0 top-1px w-12px h-12px"
              iconSrc={edit}
              onClick={() => handleEditTask(task.id)}
            />
          </Tooltip>
          <Tooltip content={disableDelete ? "Only enabled for admins and reporter users" : "Remove"}>
            <CircleIconButton
              type="secondary"
              className="delete-action mx-1"
              iconClass="far fa-trash-alt"
              onClick={() => handleOpenConfirmationModal(task.id)}
              disabled={disableDelete}
            />
          </Tooltip>
        </div>
      );
      return {
        id: task.id,
        cells: [
          { id: 'id', value: task.key, title: task.key },
          { id: 'subject', value: task.subject, title: task.key + ' - ' + task.subject, className: 'text-ellipsis overflow-hidden whitespace-nowrap' },
          { id: 'category', value: task.category.name, title: task.category.name, className: 'text-ellipsis overflow-hidden' },
          { id: 'status', value: status, title: task.status as unknown as string },
          { id: 'team', value: task.team.name, title: task.team.name, className: 'text-ellipsis overflow-hidden' },
          { id: 'assignee', value: task.assignee ? task.assignee.firstName + ' ' + task.assignee.lastName : '', title: task.assignee ? task.assignee.firstName + ' ' + task.assignee.lastName : '', className: 'text-ellipsis overflow-hidden whitespace-nowrap' },
          { id: 'priority', value: priority, title: task.priority as unknown as string },
          { id: 'deadline', value: deadline, title: formatDateDisplay(task.deadline) },
          { id: 'bookingRef', value: task.booking?.humanReadableId ?? '', title: task.booking?.humanReadableId ?? '' },
          { id: 'reporter', value: task.reporter ? task.reporter.firstName + ' ' + task.reporter.lastName : '', title: task.reporter ? task.reporter.firstName + ' ' + task.reporter.lastName : '', isHidden: currentWidth < 1920 },
          {
            id: 'actions',
            value: actions,
          },
        ]
      }
    })
  }, [tasks, currentWidth])

  return (
    <div className={classnames('task-list-page flex justify-center', { 'px-[20px]': currentWidth <= 1262, 'px-[80px]': currentWidth > 1262 })}>
      <div className="task-list-content w-full">
        {taskToDelete && (
          <ConfirmationModal
            type={EConfirmationModalType.WARNING}
            title='You are going to delete a task.'
            message='Are you sure you want to delete this task?'
            confirmButtonLabel="Yes"
            cancelButtonLabel="No"
            onConfirm={handleConfirmConfirmationModal}
            onCancel={handleCancelConfirmationModal}
            isConfirmLoading={false}
          />
        )}
        <PageTitle
          primaryText="Tasks"
          secondaryText={getPageResultsText(tasksLoading, tasksCount)}
          secondaryCondition={isInitialized && !tasksLoading}
        />
        <TaskFilters />
        {tasksLoading ? <LoadingBar /> : (
          <>
            <Table
              columns={columns}
              rows={rows}
              sortBy={taskSortBy}
              sortOrder={taskSortOrder}
              onSort={handleSort}
              messageWhenEmpty="No results"
              loading={tasksLoading}
            />
            {tasksCount !== null && tasksCount > 0 && (
              <div className="mt-[30px]">
                <Pagination
                  itemsPerPage={taskitemsPerPage}
                  pageCount={tasksPageCount}
                  currentPage={currentPage + 1}
                  onPageSelect={handlePageChange}
                  onItemsPerPageChange={handleItemsPerPageChange}
                />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};
