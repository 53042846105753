import { useCallback, useState } from 'react';
import { EPdfLogo } from 'store/modules/bookingManager/subdomains/breakdown/model';
import * as FastSearchSelectors from 'store/modules/fastSearch/selectors';
import * as BookingBuilderSelectors from 'store/modules/bookingBuilder/selectors';
import { handleDownloadQuote } from '../helpers';
import { useDispatch, useSelector } from 'react-redux';
import { ModalModes } from 'containers/SummaryForm/const';
import { isQuoteDownloadingSelector } from 'store/modules/bookingConfirmation/subdomains/quote/selectors';
import { setQuoteDownloadingAction } from 'store/modules/bookingConfirmation/subdomains/quote/actions';
import { BackendApiService, BookingBuilder, ITravelAgent } from 'services/BackendApi';
import { AxiosInstance } from 'axios';
import { AxiosWrapper } from 'services/AxiosWrapper';
import { TCountryCode } from 'interfaces';
import { iActingOnBehalfOfUser } from 'store/modules/actingOnBehalfOf/model';
import { BookingManagerApiService } from 'services/BookingManagerApi';

export const useDownloadQuote = ({
  setRequestInProgress,
  backendApi,
  bookingManagerApi,
  enqueueNotification,
  containsLiveRates,
  selectedCompanyMembership,
  closeModalRatesWarning,
  setShouldShowLeaveAlert,
  openModalRatesWarning,
  bookingBuild,
  taMarginAmountOverride,
  actingOnBehalfOfUser,
  selectedTa,
  basketBuildUuid,
  onPostRemove,
  clientCountryCode,
}: {
  setRequestInProgress: React.Dispatch<React.SetStateAction<boolean>>;
  backendApi: BackendApiService<AxiosWrapper<AxiosInstance>>;
  bookingManagerApi: BookingManagerApiService<AxiosWrapper<AxiosInstance>>;
  enqueueNotification: (notification: any) => any;
  selectedCompanyMembership: {
    uuid: string | null;
  };
  closeModalRatesWarning: () => void;
  setShouldShowLeaveAlert: React.Dispatch<React.SetStateAction<boolean>>;
  openModalRatesWarning: (mode: typeof ModalModes[keyof typeof ModalModes]) => void;
  bookingBuild: BookingBuilder | null;
  taMarginAmountOverride?: string;
  actingOnBehalfOfUser: iActingOnBehalfOfUser | null;
  selectedTa: Partial<ITravelAgent> | null;
  basketBuildUuid: string;
  onPostRemove: () => void;
  clientCountryCode: TCountryCode | null;
  containsLiveRates: boolean;
}) => {
  const dispatch = useDispatch();
  const [isQuoteMarginSelectorVisible, setQuoteMarginSelectorVisibility] = useState(false);
  const isQuoteDownloading = useSelector(isQuoteDownloadingSelector);
  const bookingDomain = useSelector(BookingBuilderSelectors.bookingSelector);

  const handleDownloadQuoteButton = useCallback(() => {
    setQuoteMarginSelectorVisibility(true);
    closeModalRatesWarning();
  }, [closeModalRatesWarning]);

  const handleDownloadQuoteModalButton = async (quoteMrginLogoOption: EPdfLogo | null) => {
    setRequestInProgress(true);
    setShouldShowLeaveAlert(false);
    let hasError = false;
    dispatch(setQuoteDownloadingAction(true));
    try {
      await handleDownloadQuote({
        bookingBuild,
        backendApi,
        bookingManagerApi,
        bookingDomain,
        enqueueNotification,
        clientCountryCode,
        quoteMrginLogoOption,
        selectedCompanyMembership,
        taMarginAmountOverride,
        actingOnBehalfOfUser: actingOnBehalfOfUser!,
        selectedTa,
        // basketBuildUuid, --deliberately commented out https://pureescapes.atlassian.net/browse/OWA-8324
        onPostRemove,
      });
    } catch (e) {
      hasError = true;
      throw e;
    } finally {
      if (!hasError) {
        setQuoteMarginSelectorVisibility(false);
      }
      dispatch(setQuoteDownloadingAction(false));
      setRequestInProgress(false);
    }

    closeModalRatesWarning();
  };

  const handleDownloadQuoteModalClose = useCallback(() => setQuoteMarginSelectorVisibility(false), []);

  const handleDownloadQuoteButtonTrigger = useCallback(() => {
    if (containsLiveRates) {
      openModalRatesWarning(ModalModes.DOWNLOAD);
    } else {
      handleDownloadQuoteButton();
    }
  }, [containsLiveRates, handleDownloadQuoteButton, openModalRatesWarning]);

  return {
    handleDownloadQuoteButtonTrigger,
    handleDownloadQuoteModalButton,
    handleDownloadQuoteButton,
    handleDownloadQuoteModalClose,
    isQuoteMarginSelectorVisible,
    isQuoteDownloading,
  };
};
