import React, { useEffect, useState } from 'react';
import { makeBackendApi } from 'services/BackendApi/BackendApi';
import { BookingBuilderResponse, PotentialBooking } from 'services/BackendApi/types';
import { ErrorBar, LoadingBar } from 'ui/NetworkStatusBar';
import { Link } from 'ui/Link';
import Helmet from 'react-helmet';
import { useRouteMatch } from 'react-router';
import * as _ from 'lodash-es';
import { useDispatch } from 'react-redux';
import { enqueueNotification } from 'store/modules/ui';
import { BasketItem, eBasketItemRenderType } from 'containers/Basket/BasketItem';
import {
  IBasketBuildL4,
  IGetSharedLinkListResponse,
  isSharedLinkListResponseActive,
  isSharedLinkListResponseExpired,
} from 'services/BackendApi/types/Basket';
import { buildToBooking, getBookingConfirmationClipboardFormat } from 'containers/Basket/components/CopyButtonMultiple';
import { getLevel2BasketBuilds } from 'containers/Basket/utils';
import { _format, formatExpiresTimeSentence } from 'utils';
import InfoIcon from 'ui/Icons/Info.component.svg';
import { SvgIcon } from 'ui/SvgIcon';
import { useQuery } from '@tanstack/react-query';
import { BasketLeadGuestInfo } from 'containers/Basket/BasketLeadGuestInfo';
import { SharedLinkPageRightColumn } from './SharedLinkPageRightColumn';
import { useCurrentWidth } from 'effects';

const extractAndCollatePaymentTerms = (potentialBooking: PotentialBooking) => {
  if (!potentialBooking) {
    return [];
  }

  let allPaymentTerms: string[] = [];
  allPaymentTerms = allPaymentTerms.concat(potentialBooking.Accommodation.map(product => product.paymentTerms));
  allPaymentTerms = allPaymentTerms.concat(potentialBooking.Fine.map(product => product.paymentTerms));
  allPaymentTerms = allPaymentTerms.concat(potentialBooking['Ground Service'].map(product => product.paymentTerms));
  allPaymentTerms = allPaymentTerms.concat(potentialBooking.Supplement.map(product => product.paymentTerms));
  allPaymentTerms = allPaymentTerms.concat(potentialBooking.Transfer.map(product => product.paymentTerms));

  return _.flatten(_.uniq(allPaymentTerms)).filter(Boolean);
};

export const SharedLinkPage = () => {
  const match = useRouteMatch<{ sharedLinkUuid: string }>();
  const sharedLinkUuid = match.params.sharedLinkUuid;
  const backendApi = makeBackendApi();
  const dispatch = useDispatch();

  const mainCompanyInfoQuery = useQuery({
    queryKey: ['shared-link-main-company-info'],
    refetchOnWindowFocus: false,
    queryFn: () => backendApi.fetchMainCompanyInfo(),
  });

  const sharedLinkContentQuery = useQuery({
    queryKey: ['shared-link-get-shared-link-content'],
    refetchOnWindowFocus: false,
    queryFn: () => backendApi.sharedLinkGetContent(sharedLinkUuid),
  });

  const [hasAnyShareItemsExpired, setHasAnyShareItemsExpired] = useState(false);
  const [basketBuilds, setBasketBuilds] = useState<IBasketBuildL4[]>([]);

  const [selectedBasketBuildUuid, setSelectedBasketBuildUuid] = useState<string | null>(null);

  const { isTabletOrMobile } = useCurrentWidth();

  useEffect(() => {
    if (
      sharedLinkContentQuery.isPending ||
      sharedLinkContentQuery.data === undefined ||
      isSharedLinkListResponseExpired(sharedLinkContentQuery.data.data)
    ) {
      return;
    }

    getLevel2BasketBuilds({
      builds: sharedLinkContentQuery.data?.data?.builds || [],
      sharedCompanyMemberships: sharedLinkContentQuery.data.data.companyMemberships,
    }).then(buildsWithPhotos => {
      // for each of the basket builds, we need to do a booking builder request
      // so we have a latest booking builder data
      Promise.all(buildsWithPhotos.map(build => backendApi.sharedLinkGetItemLatestResponse(sharedLinkUuid, build.uuid)))
        .then(responses => {
          // @ts-ignore the type is wrong - there IS a double `data` here
          const bookingBuilderDatas = responses.map(r => r.data?.data) as BookingBuilderResponse[];

          const basketBuildsWithBookingBuilderResponses: IBasketBuildL4[] = buildsWithPhotos.map(
            (build, index): IBasketBuildL4 => {
              const response = bookingBuilderDatas[index];
              return {
                ...build,
                latestBookingBuilderResponse: response!,
              };
            }
          );
          setBasketBuilds(basketBuildsWithBookingBuilderResponses);
        })
        .catch(error => {
          if (error.response.status === 410) {
            setHasAnyShareItemsExpired(true);
          }
        });
    });
  }, [sharedLinkContentQuery.isPending]);

  if (_.isNil(sharedLinkUuid)) {
    return (
      <div className="container w-1280px mx-auto pt-10">
        <ErrorBar message="No share link UUID provided" />
      </div>
    );
  }

  if (mainCompanyInfoQuery.isPending || sharedLinkContentQuery.isPending || sharedLinkContentQuery.isFetching) {
    return (
      <div className="container w-full min-w-full mx-auto mt-[100px]">
        <LoadingBar label="Loading content..." />
      </div>
    );
  }

  if (mainCompanyInfoQuery.isError || sharedLinkContentQuery.isError) {
    return (
      <div className="container w-1280px mx-auto">
        <ErrorBar />
      </div>
    );
  }

  const isShareLinkExpired =
    sharedLinkContentQuery.data.status === 410 ||
    hasAnyShareItemsExpired ||
    isSharedLinkListResponseExpired(sharedLinkContentQuery.data.data);

  const { info, logo } = mainCompanyInfoQuery.data.data.data;

  let expiresData;
  if (!isShareLinkExpired) {
    expiresData = formatExpiresTimeSentence(
      (sharedLinkContentQuery.data.data as IGetSharedLinkListResponse).expiryDate
    );
  }

  const selectedBasketItem = basketBuilds.find(item => item.uuid === selectedBasketBuildUuid);
  const sharedToTa = (sharedLinkContentQuery.data.data as IGetSharedLinkListResponse).shareType === 'ta';
  const sharedLinkContent = sharedLinkContentQuery.data.data as IGetSharedLinkListResponse;
  const logoUrl =
    sharedLinkContent.shareType === 'client' && sharedLinkContent.companyLogoUrl
      ? sharedLinkContent.companyLogoUrl
      : logo.url;

  // if we're here and no basket builds, we're still loading
  if (basketBuilds.length <= 0) {
    return (
      <div className="container w-full min-w-full mx-auto mt-[100px]">
        <LoadingBar label="Loading shared items..." />
      </div>
    );
  }

  return (
    <>
      <Helmet title="Options" />
      <div className="w-full flex flex-col space-y-40px">
        <div className="text-center shadow-pe1 py-4">
          <Link to={`/`}>
            <img
              className="mx-auto max-w-full md:max-w-[400px] max-h-[80px] px-4 md:px-0"
              src={`${logoUrl}?isIcon=true`}
              alt={info.name}
            />
          </Link>
        </div>

        {/* if anything is expired */}
        {isShareLinkExpired && (
          <div className="text-black w-full px-4 m-0 lg:container lg:mx-auto lg:w-1280px lg:px-0 font-hurmegeometric-sans text-15px leading-28px text-center uppercase children:block">
            <span>This page is no longer available.</span>
            <span>The link has expired.</span>
            <span>Please, contact the sender of this link for further assistance.</span>
          </div>
        )}

        {/* the share link isn't expired - we can render the data */}
        {!isShareLinkExpired && isSharedLinkListResponseActive(sharedLinkContentQuery.data.data) && (
          <div className="text-black w-full px-4 m-0 lg:container lg:mx-auto lg:w-1280px lg:px-0 !mb-48px lg:mb-0">
            <h1 className="font-noe-display my-0 lg:my-[20px]">Proposals for you</h1>
            <span className="block font-hurmegeometric-sans mb-[35px]">
              We are delighted to present these specially selected resorts for your review:
            </span>

            {/* the builds and info to fill out and buttons */}
            <div className="flex flex-col lg:flex-row justify-between gap-4">
              {/* the basket builds */}
              <div className="w-full flex flex-col gap-4">
                {basketBuilds.map((build, index) => (
                  <BasketItem
                    key={build.uuid}
                    build={build}
                    isSelected={selectedBasketBuildUuid === build.uuid}
                    onSelectBuild={(uuid, newState) => {
                      if (uuid === selectedBasketBuildUuid) {
                        setSelectedBasketBuildUuid(null);
                      } else {
                        setSelectedBasketBuildUuid(uuid);
                      }
                    }}
                    selectionType={sharedToTa ? 'multiple' : 'none'}
                    onEdit={() => {}}
                    onDeleteBuild={uuid => {}}
                    onUpdateBuildCommission={(buildUuid, taMarginAmount) => {}}
                    actingOnBehalfOfUser={undefined}
                    selectedTaUserUuid={null}
                    basketItemRenderType={eBasketItemRenderType.SHARED_LINK}
                    basketItemSharedTo={(sharedLinkContentQuery.data.data as IGetSharedLinkListResponse).shareType}
                    onCopy={() => {
                      const booking = buildToBooking(build);
                      const taMarginAmount = parseFloat(build.taMarginAmount || '0');
                      const text = getBookingConfirmationClipboardFormat(
                        booking,
                        extractAndCollatePaymentTerms(build.initialBuildResponse.potentialBooking),
                        [], // deliberately blank
                        {
                          isFull: ['full-without-commission', 'full-with-commission'].includes('full-with-commission'),
                          withCommission: ['summary-with-commission', 'full-with-commission'].includes(
                            'full-with-commission'
                          ),
                          commissionPercentage: taMarginAmount,
                          humanReadableId: build.humanReadableId,
                        }
                      );

                      navigator.clipboard.writeText(text).then(() => {
                        dispatch(
                          enqueueNotification({ message: 'Copied to clipboard', options: { variant: 'success' } })
                        );
                      });
                    }}
                  />
                ))}
              </div>

              {sharedToTa && ((isTabletOrMobile && selectedBasketItem) || !isTabletOrMobile) && (
                <div className="shared-page-right-hand-column pt-4 lg:pt-0 bg-white sticky lg:static bottom-0 lg:bottom-auto lg:w-[360px] w-[calc(100%+32px)] h-[calc(517px+42px)] lg:h-auto mt-6 lg:mt-0 -ml-4 lg:ml-0 px-4 lg:px-0 !-mb-[42px] lg:mb-0">
                  <SharedLinkPageRightColumn
                    sharedLinkUuid={sharedLinkUuid}
                    selectedShareItem={selectedBasketItem}
                    onRequestToBookComplete={() => {
                      sharedLinkContentQuery.refetch();
                    }}
                  />
                </div>
              )}
            </div>

            {/* expiry datetime warning */}
            <div className="flex items-center justify-around mt-5 text-15px text-gray-80 fixed bottom-0 -ml-4 lg:ml-0 bg-white w-full lg:static lg:bottom-auto px-4 pt-2 pb-2 lg:px-0 lg:pt-0 lg:pb-0">
              <span className="flex items-center gap-2">
                <SvgIcon IconComponent={InfoIcon} className="w-[18px] fill-gray-80 mt-4px" />
                <span className="uppercase">Expiration:</span>
                <span>
                  This link expires {expiresData.prefix} <span className="font-bold">{expiresData.expiresString}</span>
                </span>
              </span>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
