import React, { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ErrorBar, LoadingBar } from 'ui/NetworkStatusBar';
import { BasketRightColumn } from './BasketRightColumn';
import { LeaveWithoutSavingModal } from 'ui/LeaveWithoutSavingModal';
import { Multiselect } from 'ui/Multiselect';
import { Link } from 'react-router-dom';
import {
  ENetworkRequestStatus,
  EUserType,
  ICompany,
  ISearchQuery,
  ITravelAgent,
  IUser,
  Lodging,
  makeBackendApi,
  MealPlanNames,
} from 'services/BackendApi';
import { BasketItem, eBasketItemRenderType } from './BasketItem';
import { IBasketBuildL2, IBasketBuildL4, IBasketUserResponseItem } from 'services/BackendApi/types/Basket';
import classNames from 'classnames';
import * as AuthSelectors from 'store/modules/auth/selectors';
import { History } from 'history';
import { ERoutingPreviousPage } from 'utils/routingUtils';
import * as InventoryHeaderSelectors from 'store/modules/inventoryHeader/selectors';
import { isNil } from 'lodash-es';
import * as ActingOnBehalfOfSelectors from 'store/modules/actingOnBehalfOf/selectors';
import * as ActingOnBehalfOfActions from 'store/modules/actingOnBehalfOf/actions';
import { useRawUrlParams } from 'hooks/useRawUrlParams';
import * as BasketActions from 'store/modules/basket/actions';
import * as BookingBuilderActions from 'store/modules/bookingBuilder/actions';
import * as AgentsActions from 'store/modules/agents/actions';
import * as FastSearchActions from 'store/modules/fastSearch/actions';
import qs from 'qs';
import * as HotelActions from 'store/modules/hotel/actions';
import { APP_ROUTING_PATHS } from 'config';
import PureCheckbox from 'pureUi/Checkbox';
import { CopyButtonMultiple } from './components/CopyButtonMultiple';
import { ShareLinkButton } from './components/ShareLinkButton';
import { useDynamicParameters } from 'hooks/useDynamicParameters';
import * as _ from 'lodash-es';
import { getLevel2BasketBuilds } from './utils';

interface IBasketPageParams {
  buildUuids?: string[];
  actingOnBehalfOfUserUuid?: string;
  travelAgentUuid?: string;
}

const BASKET_OWNER_TYPE_TO_ABBR_MAP = {
  [EUserType.ADMIN]: 'ADM',
  [EUserType.SR]: 'RES',
  [EUserType.TA]: 'TA',
  [EUserType.FINANCE]: 'FIN',
  [EUserType.RL]: 'RL',
};

export const BasketPage: React.FC = React.memo(() => {
  const dispatch = useDispatch();
  const history = useHistory<History.LocationState>();
  const isInventoryHeaderOpen = useSelector(InventoryHeaderSelectors.isMenuOpenSelector);
  const isTa = useSelector(AuthSelectors.isTA);
  const loggedInUser = useSelector(AuthSelectors.getCurrentUser) as IUser;

  const params = useRawUrlParams<IBasketPageParams>();
  const {
    buildUuids: queryParamBuildUuids,
    travelAgentUuid: queryParamTaUuid,
    actingOnBehalfOfUserUuid: queryActingOnBehalfOfUserUuid,
  } = params;

  let actingOnBehalfOfUuid = useSelector(ActingOnBehalfOfSelectors.actingOnBehalfOfUuidSelector);
  let actingOnBehalfOfRole = useSelector(ActingOnBehalfOfSelectors.actingOnBehalfOfRoleSelector);
  let actingOnBehalfOfUser = useSelector(ActingOnBehalfOfSelectors.actingOnBehalfOfUserSelector);

  const { dynamicParameters } = useDynamicParameters();

  useEffect(() => {
    // if acting on behalf of is null, but we have a logged in user, assume the acting on behalf of user is the logged in user.
    // But only on page load, as user might clear field on purpose
    if (isNil(actingOnBehalfOfUser) && !isNil(loggedInUser)) {
      dispatch(
        ActingOnBehalfOfActions.setActingOnBehalfOfUserAction({
          uuid: loggedInUser.uuid,
          role: loggedInUser.type as EUserType,
        })
      );
    }
  }, []);

  const [shouldShowLeaveAlert, setShouldShowLeaveAlert] = useState(false);
  const [redirectLocation, setRedirectLocation] = useState<string | null>(null);
  const [offset, setOffset] = useState(0);
  const [builds, setBuilds] = useState<IBasketBuildL4[]>([]);
  const [totalBuilds, setTotalBuilds] = useState<number | null>(null);
  const [taCompany, setTaCompany] = useState<ICompany | null>(null);

  const [getBasketRequestPageLoad, setGetBasketRequestPageLoad] = useState<ENetworkRequestStatus>(
    ENetworkRequestStatus.IDLE
  );
  const [getBasketRequestOffsetChange, setGetBasketRequestOffsetChange] = useState<ENetworkRequestStatus>(
    ENetworkRequestStatus.IDLE
  );
  const [getBasketUsersRequest, setGetBasketUsersRequest] = useState<ENetworkRequestStatus>(ENetworkRequestStatus.IDLE);

  const [getTARequest, setGetTaRequest] = useState<ENetworkRequestStatus>(ENetworkRequestStatus.IDLE);

  const [selectedBuildUuids, setSelectedBuildUuids] = useState<string[]>(queryParamBuildUuids ?? []);
  const selectedUniqueBasketBuild =
    selectedBuildUuids.length === 1 ? builds.find(build => build.uuid === selectedBuildUuids[0]) : null;
  const [basketUsers, setBasketUsers] = useState<IBasketUserResponseItem[]>([]);

  const [selectedTaUserUuid, setSelectedTaUserUuid] = useState<string | null>(queryParamTaUuid ?? null);

  const backendApi = makeBackendApi();

  const selectedBasketUser = isNil(actingOnBehalfOfUuid)
    ? null
    : basketUsers.find(user => user.basketOwner.uuid === actingOnBehalfOfUuid);

  const isBasketOwnerDropdownDisabled =
    getBasketRequestPageLoad === ENetworkRequestStatus.PENDING ||
    getBasketRequestOffsetChange === ENetworkRequestStatus.PENDING;

  const isTaDropdownDisabled = isBasketOwnerDropdownDisabled || actingOnBehalfOfRole === EUserType.TA;

  const sortAlphabetically = (array, field) => {
    return array.sort((a, b) => a[field].localeCompare(b[field]));
  };

  useEffect(() => {
    if (redirectLocation) {
      history.push(redirectLocation, { comingFrom: ERoutingPreviousPage.BASKET_PAGE });
    }
  }, [redirectLocation, history]);

  // getting the basket users
  useEffect(() => {
    if (isTa) {
      // if we're a TA, we're always acting on behalf of ourselves
      dispatch(
        ActingOnBehalfOfActions.setActingOnBehalfOfUserAction({
          uuid: loggedInUser.uuid,
          role: loggedInUser.type as EUserType,
        })
      );
      return;
    }

    // if we're not selecting an owner via UUID, check for the current owner
    // in case that no owner is selected in previous actions just use the logged in user as owner
    if (isNil(queryActingOnBehalfOfUserUuid)) {
      dispatch(
        ActingOnBehalfOfActions.setActingOnBehalfOfUserAction({
          uuid: actingOnBehalfOfUuid || loggedInUser.uuid,
          role: loggedInUser.type as EUserType,
        })
      );
    }

    setGetBasketUsersRequest(ENetworkRequestStatus.PENDING);
    backendApi
      .getBasketUsers()
      .then(res => {
        setGetBasketUsersRequest(ENetworkRequestStatus.SUCCESS);
        setBasketUsers(res.data);

        // if we ARE setting an owner via UUID, then we need to set the actingOnBehalfOfUser
        if (queryActingOnBehalfOfUserUuid) {
          const basketUser = res.data.find(user => user.basketOwner.uuid === queryActingOnBehalfOfUserUuid);
          if (basketUser) {
            dispatch(
              ActingOnBehalfOfActions.setActingOnBehalfOfUserAction({
                uuid: queryActingOnBehalfOfUserUuid,
                role: basketUser.basketOwner.type as EUserType,
              })
            );
            // at this point, if we're also setting a TA via URL
            if (queryParamTaUuid) {
              setSelectedTaUserUuid(queryParamTaUuid);
            } else {
              // and if we're NOT setting the TA via URL, use the one that has the `isLatestBasket` flag true
              const indexOfTaWithLatestBasket = basketUser.travelAgents.findIndex(ta => ta.isLatestBasket === true);
              setSelectedTaUserUuid(
                basketUser.travelAgents[indexOfTaWithLatestBasket >= 0 ? indexOfTaWithLatestBasket : 0].uuid!
              );
            }
          }
        } else {
          const nextOnBehalfOfUuid = actingOnBehalfOfUuid || loggedInUser.uuid;
          // if we're NOT setting an owner via UUID,
          // then we just set selected TA as the first one in the list for the owner
          const me = res.data.find(user => user.basketOwner.uuid === nextOnBehalfOfUuid);
          if (me && me.travelAgents.length >= 1 && isNil(selectedTaUserUuid)) {
            // if we have a TA UUID in the URL, use that. otherwise, use the one that has the `isLatestBasket` flag true
            // just fyi in this scenario, if the user provides a ta uuid in the URL that DOESNT belong to the user, it'll error out
            // and not select a TA and make the user pick one manually; which is correct imo
            if (queryParamTaUuid) {
              setSelectedTaUserUuid(queryParamTaUuid);
            } else {
              const indexOfTaWithLatestBasket = me.travelAgents.findIndex(ta => ta.isLatestBasket === true);
              setSelectedTaUserUuid(
                me.travelAgents[indexOfTaWithLatestBasket >= 0 ? indexOfTaWithLatestBasket : 0].uuid!
              );
            }
          }
        }
      })
      .catch(e => {
        setGetBasketUsersRequest(ENetworkRequestStatus.ERROR);
      });
  }, [isTa]);

  // a TA is selected then update the redux part for Agents
  // As commented with Tom, we are starting to have 2 concepts of TA
  // the TA from the BB as we used to have and now the "TA selected from dropdown basket"
  // Be aware that any of the 2 can be overwritten in that field of redux
  // This is the TA that will be used to req BE to create a booking and to show in <BasketTravelAgentInfo/>
  useEffect(() => {
    // if we ARE a TA, set the company
    if (isTa) {
      setTaCompany((loggedInUser as ITravelAgent).company!);
      return;
    }
    if (!selectedTaUserUuid) {
      return;
    }
    setGetTaRequest(ENetworkRequestStatus.PENDING);
    // get the TA object with company for the selected ta
    // and put it into the agents domain, and store
    // the company locally (it gets used in the right column)
    backendApi
      .getTravelAgentViaUuid(selectedTaUserUuid, ['company'])
      .then(res => {
        setGetTaRequest(ENetworkRequestStatus.SUCCESS);
        const ta = res.data.data;
        dispatch(AgentsActions.selectedTaChangeAction(ta));
        setTaCompany(res.data.data.company!);
      })
      .catch(e => {
        setGetTaRequest(ENetworkRequestStatus.ERROR);
        console.error('something went wrong', e);
      });
  }, [selectedTaUserUuid]);

  // getting the basket items
  useEffect(() => {
    if (!isTa && (isNil(actingOnBehalfOfUuid) || isNil(selectedTaUserUuid))) {
      return;
    }

    offset === 0
      ? setGetBasketRequestPageLoad(ENetworkRequestStatus.PENDING)
      : setGetBasketRequestOffsetChange(ENetworkRequestStatus.PENDING);

    // get the basket builds...
    backendApi
      .getBasket({
        offset,
        travelAgentUuid: isTa ? undefined : selectedTaUserUuid ?? undefined,
        actingOnBehalfOfUserUuid: isTa ? undefined : actingOnBehalfOfUuid ?? undefined,
      })
      //...then add the hotels
      .then(async res => {
        const buildsLevel2 = await getLevel2BasketBuilds({
          builds: res.data?.builds ?? [],
          backendApi,
        });

        return {
          buildsLevel2,
          totalBuilds: res.data.total,
        };
      })
      // then get the latest booking builder responses
      .then(async ({ buildsLevel2, totalBuilds }: { buildsLevel2: IBasketBuildL2[]; totalBuilds: number }) => {
        // for each of the basket builds, we need to do a booking builder request
        // so we have a latest booking builder data
        let responses: any[];
        try {
          responses = await Promise.allSettled(
            buildsLevel2.map(build => {
              return backendApi.postBookingBuilderRequest({
                bookingBuilderRequest: build.buildRequest,
                clientCountryCode: build.clientCountryCode, // we always send it, even if you're a TA, this function will sort out whether it actually needs to go up on the request
                travelAgentUuid: selectedTaUserUuid!,
                validateStatusFunc: status => (status >= 200 && status <= 299) || (status >= 400 && status <= 499),
                actingOnBehalfOfUser: actingOnBehalfOfUser ?? undefined,
              });
            })
          );
        } catch (e) {
          console.error('error with responses', e);
        }

        const basketBuildsWithBookingBuilderResponses: IBasketBuildL4[] = buildsLevel2.map(
          (build, index): IBasketBuildL4 => {
            const response = responses[index];

            if (response && response.value) {
              return {
                ...build,
                latestBookingBuilderResponse: response.value.data.data,
              };
            } else {
              return {
                ...build,
                latestBookingBuilderResponse: null!,
              };
            }
          }
        );

        setBuilds([...builds, ...basketBuildsWithBookingBuilderResponses]);
        setTotalBuilds(totalBuilds);
        offset === 0
          ? setGetBasketRequestPageLoad(ENetworkRequestStatus.SUCCESS)
          : setGetBasketRequestOffsetChange(ENetworkRequestStatus.SUCCESS);
      })
      .catch(error => {
        console.error(error);
        offset === 0
          ? setGetBasketRequestPageLoad(ENetworkRequestStatus.ERROR)
          : setGetBasketRequestOffsetChange(ENetworkRequestStatus.ERROR);
      });
  }, [isTa, actingOnBehalfOfUuid, selectedTaUserUuid, offset]);

  const onEdit = (build: IBasketBuildL4) => {
    const bookingBuild = {
      request: build.buildRequest,
      response: build.latestBookingBuilderResponse!,
      bookingErrors: build.latestBookingBuilderResponse?.errors || [],
    };

    // the startDate and endDate below need to be the booking start and end dates
    const searchQuery: ISearchQuery = {
      name: null,
      lodgings: build.buildRequest.Accommodation.map(
        (accommodation): Lodging => ({
          numberOfAdults: accommodation.guestAges.numberOfAdults,
          agesOfAllChildren: accommodation.guestAges.agesOfAllChildren,
          repeatCustomer: accommodation.repeatCustomer,
        })
      ),
      startDate: build.buildRequest.startDate,
      endDate: build.buildRequest.endDate,
      mealPlanCategories: [MealPlanNames.ANY],
      regions: [],
      starRatings: [],
      filters: [],
      priceRange: {
        min: undefined,
        max: undefined,
      },
      clientCountryCode: !isTa ? build.clientCountryCode : null,
    };

    const searchQueryString = qs.stringify(searchQuery);
    dispatch(HotelActions.clearHotelAction());
    dispatch(FastSearchActions.populateQueryAction(searchQuery));
    dispatch(BookingBuilderActions.setCurrentBookingBuilderAction(bookingBuild));
    dispatch(BasketActions.setBasketBuildAction(build));

    setRedirectLocation(`${APP_ROUTING_PATHS.basket.edit}/${build.buildRequest.hotelUuid}?${searchQueryString}`);
  };

  // Assume BE has done its job and removed basketBuild from basket in DB properly
  // Then just remove the view here.
  const handleRemoveBuildFromLocalList = (basketBuildUuid?: string) => {
    setBuilds(builds.filter(build => build.uuid !== basketBuildUuid));
    setSelectedBuildUuids(selectedBuildUuids.filter(uuid => uuid !== basketBuildUuid));
    setTotalBuilds(isNil(totalBuilds) || totalBuilds === 0 ? 0 : totalBuilds - 1);
  };

  return (
    <div className="basket-page container mx-auto max-w-[1800px] font-pt-sans text-black px-[20px]">
      <div className="basket-columns flex gap-[40px]">
        {/* the widths are the size of the right coluimn AND the x padding of the container */}
        <div
          className={classNames('basket-left-column flex flex-col w-[calc(100%-280px)] lg:w-[calc(100%-390px)]', {
            'opacity-50 pointer-events-none': getBasketRequestOffsetChange === ENetworkRequestStatus.PENDING,
          })}
        >
          <div className="page-title-container flex items-center justify-between mb-20px">
            <h1 className={'primary-title font-noe-display text-4xl leading-46px text-black font-normal m-0'}>
              Basket
            </h1>
          </div>

          {/* the "user" dropdowns */}
          {!isTa && getBasketUsersRequest === ENetworkRequestStatus.PENDING && <LoadingBar />}
          {!isTa && getBasketUsersRequest === ENetworkRequestStatus.ERROR && <ErrorBar />}
          {!isTa && getBasketUsersRequest === ENetworkRequestStatus.SUCCESS && (
            <div className="filters grid grid-cols-2 gap-5 mb-20px">
              <div className="flex flex-col space-y-2 w-full">
                <span className="uppercase">Basket Created by *</span>
                <span
                  className={classNames({
                    'opacity-50 pointer-events-none': isBasketOwnerDropdownDisabled,
                  })}
                >
                  <Multiselect
                    disabled={isBasketOwnerDropdownDisabled}
                    className="bg-ivory !font-hurmegeometric-sans"
                    itemsClassname="bg-ivory"
                    itemCtaClassName="hover:bg-gray-20"
                    itemContentClassName="!font-hurmegeometric-sans"
                    fontClass="!font-hurmegeometric-sans"
                    options={sortAlphabetically(
                      basketUsers.map(bu => {
                        return {
                          value: bu.basketOwner.uuid,
                          label: `${BASKET_OWNER_TYPE_TO_ABBR_MAP[bu.basketOwner.type]} - ${bu.basketOwner.firstName} ${
                            bu.basketOwner.lastName
                          }::${bu.basketOwner.type}`,
                        };
                      }),
                      'label'
                    )}
                    isCloseOnSelect={true}
                    hideCheckboxes={true}
                    isSingleSelectMode={true}
                    optionsLabelRenderer={o => o.label.split('::')[0]}
                    selectedValuesRenderer={v => {
                      const [label, role] = v.split('::');
                      return label;
                    }}
                    isEnableLookupSearch={true}
                    onLookupSearch={(searchTerm, signal, cancelTokenSource) => {
                      return new Promise(resolve => {
                        resolve(
                          sortAlphabetically(
                            basketUsers
                              .map(bu => {
                                return {
                                  value: bu.basketOwner.uuid,
                                  label: `${BASKET_OWNER_TYPE_TO_ABBR_MAP[bu.basketOwner.type]} - ${
                                    bu.basketOwner.firstName
                                  } ${bu.basketOwner.lastName}::${bu.basketOwner.type}`,
                                };
                              })
                              .filter(pair => pair.label.toLowerCase().includes(searchTerm.toLowerCase())),
                            'label'
                          )
                        );
                      });
                    }}
                    onUpdate={(sv, isCustom, options) => {
                      if (sv.length <= 0) {
                        dispatch(ActingOnBehalfOfActions.setActingOnBehalfOfUserAction(null));
                        setSelectedTaUserUuid(null);
                      } else {
                        const user = options.find(o => o.value === sv[0]);
                        const [label, role] = user.label.split('::');
                        dispatch(
                          ActingOnBehalfOfActions.setActingOnBehalfOfUserAction({
                            uuid: sv[0],
                            role,
                          })
                        );
                        // if we've just selected a TA as the basket owner, we need to set the TA UUID
                        // as them too
                        if (role === EUserType.TA) {
                          setSelectedTaUserUuid(sv[0]);
                        } else {
                          setSelectedTaUserUuid(null);
                        }
                      }
                      dispatch(BookingBuilderActions.clearBookingBuilderGuestDetailsAction());
                      setOffset(0);
                      setBuilds([]);
                      setSelectedBuildUuids([]);
                      setGetBasketRequestPageLoad(ENetworkRequestStatus.IDLE);
                    }}
                    selectedValues={selectedBasketUser ? [selectedBasketUser.basketOwner.uuid] : []}
                    isEnableFuzzySearch={true}
                  />
                </span>
              </div>
              <div className="flex flex-col space-y-2 w-full">
                <span className="uppercase">Travel Company - Travel Agent *</span>
                <span
                  className={classNames({
                    'opacity-50 pointer-events-none': isTaDropdownDisabled,
                  })}
                >
                  <Multiselect
                    disabled={isTaDropdownDisabled}
                    className="bg-ivory !font-hurmegeometric-sans"
                    itemsClassname="bg-ivory"
                    itemCtaClassName="hover:bg-gray-20"
                    itemContentClassName="!font-hurmegeometric-sans"
                    fontClass="!font-hurmegeometric-sans"
                    options={sortAlphabetically(
                      selectedBasketUser
                        ? selectedBasketUser.travelAgents.map(ta => {
                            return {
                              value: ta.uuid,
                              label: `${ta.company.name} - ${ta.firstName} ${ta.lastName}`,
                            };
                          })
                        : [],
                      'label'
                    )}
                    isEnableLookupSearch={true}
                    onLookupSearch={(searchTerm, signal, cancelTokenSource) => {
                      return new Promise(resolve => {
                        resolve(
                          sortAlphabetically(
                            selectedBasketUser
                              ? selectedBasketUser.travelAgents
                                  .map(ta => {
                                    return {
                                      value: ta.uuid,
                                      label: `${ta.company.name} - ${ta.firstName} ${ta.lastName}`,
                                    };
                                  })
                                  .filter(pair => pair.label.toLowerCase().includes(searchTerm.toLowerCase()))
                              : [],
                            'label'
                          )
                        );
                      });
                    }}
                    isCloseOnSelect={true}
                    hideCheckboxes={true}
                    isSingleSelectMode={true}
                    onUpdate={sv => {
                      if (sv.length <= 0) {
                        setSelectedTaUserUuid(null);
                        setOffset(0);
                        setBuilds([]);
                        setSelectedBuildUuids([]);
                        setGetBasketRequestPageLoad(ENetworkRequestStatus.IDLE);
                        dispatch(BookingBuilderActions.clearBookingBuilderGuestDetailsAction());
                      }
                      if (sv.length >= 1) {
                        setSelectedTaUserUuid(sv[0]);
                        setOffset(0);
                        setBuilds([]);
                        setSelectedBuildUuids([]);
                        setGetBasketRequestPageLoad(ENetworkRequestStatus.IDLE);
                        dispatch(BookingBuilderActions.clearBookingBuilderGuestDetailsAction());
                      }
                    }}
                    selectedValues={selectedTaUserUuid ? [selectedTaUserUuid] : []}
                    isEnableFuzzySearch={true}
                  />
                </span>
              </div>
            </div>
          )}

          {/* Header + Actions */}
          {getBasketRequestPageLoad !== ENetworkRequestStatus.IDLE && (
            <div className="page-title-container flex items-center justify-between">
              <div className="flex items-left items-center justify-between">
                <span className="ml-5">
                  <span className={builds.length === selectedBuildUuids.length ? '' : 'opacity-50'}>
                    <PureCheckbox
                      onChange={() => {
                        if (builds.length === selectedBuildUuids.length) {
                          setSelectedBuildUuids([]);
                          return;
                        }
                        setSelectedBuildUuids([...builds.map(build => build.uuid)]);
                      }}
                      checked={selectedBuildUuids.length > 0}
                      className="checkbox w-[25px] h-[25px]"
                    />
                  </span>
                </span>
                <h1 className={'primary-title font-noe-display text-26px leading-33 text-black font-normal m-0 ml-5'}>
                  Select Product
                </h1>
              </div>
              <div className="flex items-right justify-between">
                {dynamicParameters.ENABLE_SHARE_BASKET_TEMP && (
                  <ShareLinkButton
                    builds={builds.filter(build => selectedBuildUuids.includes(build.uuid))}
                    isTa={isTa}
                    selectedTaUserUuid={selectedTaUserUuid}
                    actingOnBehalfOfUuid={actingOnBehalfOfUuid}
                  />
                )}
                <CopyButtonMultiple builds={builds.filter(build => selectedBuildUuids.includes(build.uuid))} />
              </div>
            </div>
          )}

          {/* results but no results */}
          {getBasketRequestPageLoad === ENetworkRequestStatus.PENDING &&
            getBasketUsersRequest !== ENetworkRequestStatus.PENDING && <LoadingBar />}
          {getBasketRequestPageLoad === ENetworkRequestStatus.ERROR && <ErrorBar />}
          {(getBasketRequestPageLoad === ENetworkRequestStatus.SUCCESS && builds.length <= 0 && (
            <div className="flex flex-col">
              <span className="text-15px leading-18px mb-20px">No results found</span>

              <Link
                className="block self-start bg-white border border-brown-prime font-hurmegeometric-sans uppercase px-10px py-[9px] text-brown-prime text-sm"
                to="/filters"
              >
                <span className="text-brown-prime">Search Products</span>
              </Link>
            </div>
          )) ??
            null}

          {/* results */}
          <div
            className={classNames('results flex flex-col space-y-10px mt-20px', {
              'opacity-50 pointer-events-none':
                getBasketRequestPageLoad === ENetworkRequestStatus.PENDING ||
                getTARequest === ENetworkRequestStatus.PENDING,
            })}
          >
            {getBasketRequestPageLoad === ENetworkRequestStatus.SUCCESS &&
              builds.map(build => (
                <BasketItem
                  key={build.uuid}
                  build={build}
                  isSelected={selectedBuildUuids.includes(build.uuid)}
                  onSelectBuild={(uuid, newState) => {
                    if (newState) {
                      setSelectedBuildUuids(oldArray => [...oldArray, uuid]);
                    } else {
                      setSelectedBuildUuids(oldArray => oldArray.filter(selectedBuid => selectedBuid !== uuid));
                    }
                  }}
                  selectionType="multiple"
                  onEdit={() => onEdit(build)}
                  onDeleteBuild={uuid => {
                    setSelectedBuildUuids(oldArray => oldArray.filter(selectedBuid => selectedBuid !== uuid));
                    // We delete element locally rather than fetching them again
                    // in order to keep pointer and pagination information for the user
                    const remainingBuilds = builds.filter(b => b.uuid !== uuid);
                    setBuilds(remainingBuilds);
                    setTotalBuilds(totalBuilds ? totalBuilds - 1 : 0);
                  }}
                  onUpdateBuildCommission={(buildUuid, taMarginAmount) => {
                    setBuilds(oldArray => {
                      return oldArray.map(build => (build.uuid === buildUuid ? { ...build, taMarginAmount } : build));
                    });
                  }}
                  actingOnBehalfOfUser={actingOnBehalfOfUser || undefined}
                  selectedTaUserUuid={selectedTaUserUuid}
                  basketItemRenderType={eBasketItemRenderType.BASKET_PAGE}
                />
              ))}
          </div>

          {/* the load more button */}
          {builds.length < (totalBuilds || 0) && getBasketRequestPageLoad === ENetworkRequestStatus.SUCCESS && (
            <button
              className="bg-white mt-20px self-center block border border-brown-prime font-hurmegeometric-sans uppercase px-10px py-[9px] text-brown-prime text-sm items-center space-x-5px cursor-pointer min-w-[125px]"
              disabled={getBasketRequestOffsetChange === ENetworkRequestStatus.PENDING}
              onClick={() => {
                setOffset(builds.length);
              }}
            >
              {getBasketRequestOffsetChange === ENetworkRequestStatus.PENDING ? (
                <i className="fas fa-circle-notch fa-spin text-brown-140"></i>
              ) : (
                `Load More`
              )}
            </button>
          )}
        </div>

        <div
          className={classNames(
            'basket-right-column-wrapper fixed right-[20px] w-[260px] lg:w-[370px] transition-[top] duration-300 overflow-x-clip',
            {
              // the odd heights are to ensure that we can always scroll down the right hand side and see everything
              // we need them because the right column is position:fixed
              'overflow-y-auto max-h-[calc(100vh-200px)] top-[190px]': isInventoryHeaderOpen,
              'overflow-y-auto max-h-[calc(100vh-150px)] top-[140px]': !isInventoryHeaderOpen,
            }
          )}
        >
          {taCompany && (
            <BasketRightColumn
              basketBuild={selectedUniqueBasketBuild || null}
              selectedBuildsCount={selectedBuildUuids.length || 0}
              shouldShowLeaveAlert={shouldShowLeaveAlert}
              setShouldShowLeaveAlert={setShouldShowLeaveAlert}
              setRedirectLocation={setRedirectLocation}
              onPostRemove={handleRemoveBuildFromLocalList}
              company={taCompany}
              clientCountryCode={selectedUniqueBasketBuild?.clientCountryCode || null}
            />
          )}
        </div>
      </div>

      <LeaveWithoutSavingModal
        title="You have made changes to this page, and not saved. If you leave this page now, these changes will be lost."
        when={shouldShowLeaveAlert}
        confirmButtonLabel="Yes"
        cancelButtonLabel="No"
      />
    </div>
  );
});
