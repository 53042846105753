import React, { useCallback } from "react";
import { ENetworkRequestStatus } from "services/BackendApi";
import * as BreakdownActions from 'store/modules/bookingManager/subdomains/breakdown/actions';
import { useDispatch } from "react-redux";
import Checkbox from "ui/Checkbox";

interface IIsRepeatGuestInputProps {
  isRepeatGuest: boolean | undefined;
  disabled?: boolean;
}

export const IsRepeatGuestInput: React.FC<IIsRepeatGuestInputProps> = ({ disabled, isRepeatGuest }) => {
  const dispatch = useDispatch();

  const handleRepeatGuestSelect = useCallback(e => {
    dispatch(BreakdownActions.editAccommodationSetSelectedRepeatGuestAction(e.target.checked));
    dispatch(BreakdownActions.editAccommodationBookingBuildRequestAction());
    dispatch(BreakdownActions.editAccommodationSetHasTochedAction());
  }, [dispatch]);

  return (
    <div className="is-repeat-guest-inputs">
      <label className="flex items-center justify-start text-black cursor-pointer">
        <Checkbox
          checked={isRepeatGuest}
          onChange={handleRepeatGuestSelect}
          disabled={disabled}
        />
        &nbsp;&nbsp;Repeat Guest
      </label>
    </div>
  )
};
