import * as Yup from 'yup';

export const companyCreateFormValidationSchema = Yup.object().shape({
  type: Yup.string().required('Field is required'),
  name: Yup.string().required('Field is required'),
  country: Yup.string().nullable(true),
  keyNotes: Yup.string().nullable(true),
});

export interface ICompanyCreateFormValues {
  type: string;
  name: string;
  country: string | null;
  keyNotes: string | null;
}