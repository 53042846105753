import { useState, useCallback } from 'react';

import { windowExists, addEvent, removeEvent } from 'utils';
import { useEffectBoundary } from './genericEffects';

const BREAKPOINTS = {
  mobile: 479,
  mobileLandscape: 767,
  tablet: 990,
  desktop: 1440,
  desktopHD: Number.MAX_SAFE_INTEGER,
};

/**
 * useCurrentWidth
 *
 * Custom hook that returns the current width of the window and
 * whether the window isMobile
 *
 * @returns {object}
 */
export const useCurrentWidth = () => {
  const [currentWidth, setCurrentWidth] = useState(windowExists.innerWidth || 0);
  const updateWidth = useCallback(() => setCurrentWidth(windowExists.innerWidth || 0), []);

  useEffectBoundary(() => {
    addEvent('resize', updateWidth);

    () => {
      removeEvent('resize', updateWidth);
    };
  });

  const isMobile = currentWidth <= BREAKPOINTS.mobile;
  const isMobileLandscape = currentWidth > BREAKPOINTS.mobile && currentWidth <= BREAKPOINTS.mobileLandscape;
  const isTablet = currentWidth > BREAKPOINTS.mobileLandscape && currentWidth <= BREAKPOINTS.tablet;
  const isDesktop = currentWidth > BREAKPOINTS.tablet && currentWidth <= BREAKPOINTS.desktop;
  const isDesktopHD = currentWidth > BREAKPOINTS.desktop && currentWidth <= BREAKPOINTS.desktopHD;
  const isTabletOrMobile = isMobile || isMobileLandscape || isTablet;

  return { currentWidth, isMobile, isMobileLandscape, isTablet, isDesktop, isDesktopHD, isTabletOrMobile };
};

/**
 * Scroll to top
 *
 * Wrapper around window exists scrollTo
 */
const scrollToTop = () => {
  if (windowExists.scrollTo) {
    windowExists.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }
};

/**
 * useScrollToTop
 *
 * Custom hook to scroll the page to the top
 *
 * @param {Array} changed
 */
export const useScrollToTop = changed => useEffectBoundary(scrollToTop, [changed]);
