import React, { useCallback, useMemo } from 'react';
import { LoadingBar } from 'ui/NetworkStatusBar';
import { ENetworkRequestStatus } from 'services/BackendApi/types/Generic';
import { useDispatch, useSelector } from 'react-redux';
import * as BreakdownSelectors from 'store/modules/bookingManager/subdomains/breakdown/selectors';
import * as BreakdownActions from 'store/modules/bookingManager/subdomains/breakdown/actions';
import { ModalV1 } from 'ui/ModalV1';
import { getCurrencyBySymbol } from 'utils';
import { RHPOffers } from '../AddAccommodationModal/RightHandPanel/RHPOffers';
import { RHPServiceCharge } from '../AddAccommodationModal/RightHandPanel/RHPServiceCharge';
import { AddAncillaryRightHandPanelTotalCost } from 'ui/AddAncillarySharedComponents/AddAncillaryRightHandPanelTotalCost';
import FluidButton from 'ui/FluidButton';
import { IStaticRate } from '../AddAccommodationModal/MealPlanDropdown/types';
import { convertMealPlanToStaticRate } from '../AddAccommodationModal/helpers';
import classNames from 'classnames';
import { PeopleAndDatesInputs } from './inputs/PeopleAndDatesInputs';
import { MealPlanAndOccasionInputs } from './inputs/MealPlanAndOccasionInputs';
import { IsRepeatGuestInput } from './inputs/IsRepeatGuestInput';

export interface IEditAccommodationModalProps {
  bookingUuid: string;
  bookingCurrency: string;
};


export const EditAccommodationModal = ({ bookingCurrency, bookingUuid }: IEditAccommodationModalProps) => {
  const dispatch = useDispatch();
  const guestAges = useSelector(BreakdownSelectors.editAccommodationGuestAgesSelector);
  const selectedDates = useSelector(BreakdownSelectors.editAccommodationSelectedDatesSelector);
  const occasions = useSelector(BreakdownSelectors.editAccommodationOccasionsSelector);
  const selectedMealPlan = useSelector(BreakdownSelectors.editAccommodationSelectedMealPlanSelector);
  const isRepeatGuest = useSelector(BreakdownSelectors.editAccommodationIsRepeatGuestSelector);
  const accommodationDetails = useSelector(BreakdownSelectors.editAccommodationAccommodationDetailsSelector);
  const selectedBuild = useSelector(BreakdownSelectors.editAccommodationSelectedBuildSelector);
  const initiateBookingBuildRequest = useSelector(BreakdownSelectors.editAccommodationInitiateBookingBuildRequestSelector);
  const bookingBuildRequest = useSelector(BreakdownSelectors.editAccommodationBookingBuildRequestSelector);
  const lastRequestedBuild = useSelector(BreakdownSelectors.editAccommodationLastRequestedBuildSelector);
  const headlineLineItemBreakdown = useSelector(BreakdownSelectors.headlineLineItemBreakdownSelector);
  const countryData = useSelector(BreakdownSelectors.editAccommodationCountryDataSelector);
  const simulateBreakdownRequest = useSelector(BreakdownSelectors.editAccommodationSimulateBreakdownRequestSelector);
  const errors = useSelector(BreakdownSelectors.editAccommodationErrorsSelector);
  const hasEdited = useSelector(BreakdownSelectors.editAccommodationHasEditedSelector);

  const errorsWarnings = useMemo(() => errors?.warnings || [], [errors?.warnings]);
  const errorsStops = useMemo(() => errors?.stops || [], [errors?.stops]);
  const mealPlanOptions: IStaticRate[] = useMemo(() => selectedBuild?.availableProductSets.Accommodation[0].availableSubProductSets['Meal Plan'].map(
    item => convertMealPlanToStaticRate(item)
  ) || [], [selectedBuild]);
  const disableForm = useMemo(() => bookingBuildRequest === ENetworkRequestStatus.PENDING || initiateBookingBuildRequest === ENetworkRequestStatus.ERROR || errorsStops.length > 0, [bookingBuildRequest, errorsStops, initiateBookingBuildRequest]);
  const disableSaveButton = useMemo(() => disableForm || !hasEdited, [disableForm, hasEdited]);

  const handleCloseEditAccommodationModal = useCallback(() => {
    dispatch(BreakdownActions.editAccommodationCloseModalAction());
  }, [dispatch]);

  const handleSaveAccommodationButton = useCallback(async () => {
    if (!headlineLineItemBreakdown || !selectedBuild || !lastRequestedBuild || !countryData) {
      return;
    }

    dispatch(BreakdownActions.editAccommodationSimulateBreakdownRequestAction(bookingUuid));

  }, [dispatch, selectedBuild, lastRequestedBuild]);

  const handleCancelAccommodationButton = useCallback(() => {
    dispatch(BreakdownActions.editAccommodationCloseModalAction());
  }, [])

  if (!guestAges || initiateBookingBuildRequest === ENetworkRequestStatus.IDLE) { return null; }

  if (initiateBookingBuildRequest === ENetworkRequestStatus.PENDING) {
    return (
      <ModalV1
        onClose={handleCloseEditAccommodationModal}
        showCloseButton
        className="edit-accommodatiom-modal-container font-pt-sans min-w-[650px] min-h-[590px]"
        title="Edit Accommodation"
      >
        <div>
          <LoadingBar />
        </div>
      </ModalV1>
    );
  }


  return (
    <ModalV1
      className={"edit-accommodatiom-modal-container font-pt-sans min-w-[650px] min-h-[590px] flex flex-col"}
      showCloseButton
      onClose={handleCloseEditAccommodationModal}
      title="Edit Accommodation"
    >
      {(errorsStops.length > 0 || initiateBookingBuildRequest === ENetworkRequestStatus.ERROR) && <div className="text-red-95">This room can't be edited. Please, review the information with the System Admin.</div>}
      <div className='flex-1'>
        <div className="hotel-and-room-name mb-[25px]">
          <p className="hotel-name font-bold text-[17px] m-0 pb-[5px]">{accommodationDetails?.hotelName}</p>
          <p className="room-name text-[15px] m-0">{accommodationDetails?.roomName}</p>
        </div>
        <div className={classNames('inputs', {
          'opacity-50': bookingBuildRequest === ENetworkRequestStatus.PENDING || bookingBuildRequest === ENetworkRequestStatus.ERROR || initiateBookingBuildRequest === ENetworkRequestStatus.ERROR || simulateBreakdownRequest === ENetworkRequestStatus.PENDING || errorsStops.length > 0,
        })}>
          <PeopleAndDatesInputs disabled={disableForm} guestAges={guestAges} selectedDates={selectedDates} />
          <MealPlanAndOccasionInputs disabled={disableForm} bookingBuildRequest={bookingBuildRequest} bookingCurrency={bookingCurrency} mealPlanOptions={mealPlanOptions} occasions={occasions} selectedMealPlan={selectedMealPlan} />
          <IsRepeatGuestInput disabled={disableForm} isRepeatGuest={isRepeatGuest} />
          {selectedBuild && <div className="bottom-part space-y-10px pt-10px">
            <RHPOffers selectedBuild={selectedBuild} />

            <RHPServiceCharge currencySymbol={getCurrencyBySymbol(bookingCurrency || '').symbol} selectedBuild={selectedBuild} />

            {errorsWarnings.map((warning, index) => (
              <p key={index} className="text-yellow-60 text-xs mt-2">{warning}</p>
            ))}
  
            {!disableSaveButton && (
              <p className="text-yellow-60 text-xs mt-2">Any manually edited prices in the breakdown will be replaced with the latest available data</p>
            )}
          </div>}
        </div>
      </div>
      {selectedBuild && (
        <AddAncillaryRightHandPanelTotalCost
          price={selectedBuild.totals.total}
          isOnRequest={selectedBuild.totals.oneOrMoreItemsOnRequest}
          currencySymbol={getCurrencyBySymbol(bookingCurrency || '').symbol}
        />
      )}
      <div className="buttons flex gap-5 w-[130px] !mt-[20px]">
        <FluidButton
          onClick={handleCancelAccommodationButton}
          className="block w-full"
          type="secondary"
        >
          Cancel
        </FluidButton>
        <FluidButton
          disabled={disableSaveButton}
          isLoading={simulateBreakdownRequest === ENetworkRequestStatus.PENDING}
          onClick={handleSaveAccommodationButton}
          className="block w-full"
          type="primary"
        >
          Save
        </FluidButton>
      </div>

    </ModalV1>
  );
};
