import React, { useEffect, useCallback, useState } from 'react';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import * as BreakdownSelectors from 'store/modules/bookingManager/subdomains/breakdown/selectors';
import * as BreakdownActions from 'store/modules/bookingManager/subdomains/breakdown/actions';
import styled from 'styled-components';
import { formatPrice, generateArrayOfDatesBetween, getOverlappingDates, isBlank } from 'utils';
import {
  EBookingStatus,
  ERateSource,
  IHeadlineLineItemBreakdownAccommodationLineItem,
} from 'services/BookingManagerApi';
import { PriceAsCentsInput } from '../stateful/PriceAsCentsInput';
import { BreakdownHoldBar } from '../BreakdownHoldBar';

import { ProductsBlock } from './ProductsBlock';
import { toClassName } from './helpers';

import InfoIcon from '../Icons/Info.component.svg';
import { theme } from '../../../tailwind.config';
import { HidingTooltip } from '../Tooltip';

import { ENetworkRequestStatus } from 'services/BackendApi';
import FluidButton from 'ui/FluidButton';
import * as DateFNS from 'date-fns';
import { DateHelper } from 'pureUi/DatePicker';
import { DatePickerStateProvider, IDatePickerSateParams } from 'pureUi/providers/DatePickerStateProvider';
import DateRangeInput from 'pureUi/DateRangeInput';
import { SvgIcon } from 'ui/SvgIcon';
import CloseIcon from 'ui/Icons/close.component.svg';
import { isNilOrEmpty } from 'ramda-adjunct';
import { RemoveHeadlineButton, EditHeadlineButton } from './HeadlineActionButtons';
import { useDynamicParameters } from 'hooks/useDynamicParameters';
import { isNil } from 'lodash-es';
import { hasOverlap } from 'utils/array';
import { hotelDetailsSelector } from 'store/modules/bookingManager/subdomains/hotelDetails/selectors';

interface IAccommodationBlockProps {
  key: string;
  index: number;
  accom: IHeadlineLineItemBreakdownAccommodationLineItem;
  bookingCurrencySymbol: string;
  bookingStatus: string;
  setHeadlineLineItemFieldAction: any;
  removeHeadlineLineItemAction: any;
  addHeadlineLineItemAction: any;
  setBreakdownHoldAction: any;
  isEditable: boolean;
  isPurchaseCostEditable: boolean;
  isSR: boolean;
  isTA: boolean;
  isAdmin: boolean;
  isFinanceUser: boolean;
  isLiveRate: boolean;
  isLatestVersion: boolean;
  allAccommodationItems: IHeadlineLineItemBreakdownAccommodationLineItem[];
  bookingUuid: string;
}

// necessary because we dont have placeholder utility classes
const AccommodationTopInput = styled.input`
  &::placeholder {
    font-weight: normal;
    font-size: 16px;
    font-style: italic;
    color: #968f8b; // gray-100
  }
`;

// necessary because we need it to be a different border colour than normal
// and the date range input is a super nested styled component
// @ts-ignore ts getting confused by `styled` props
const StyledDateRangeInput = styled(DateRangeInput)`
  .pseudoSelect {
    border-color: #d5d2d1; //gray-40
  }
`;

export const AccommodationBlock = (props: IAccommodationBlockProps) => {
  const dispatch = useDispatch();
  const { dynamicParameters } = useDynamicParameters();
  const accom: IHeadlineLineItemBreakdownAccommodationLineItem = props.accom;
  const index = props.index;
  const bookingStatus = props.bookingStatus;
  const enabledAccomInfo = dynamicParameters.ENABLE_EDIT_ACCOMM_MODALS_TEMP && !isNil(accom.requestMeta) && accom.enableEditAccommodationModal;
  const isInternalUser = props.isSR || props.isAdmin || props.isFinanceUser;
  const showEditIcon = enabledAccomInfo && !props.isLiveRate && isInternalUser;
  const showRemoveIcon = !props.isLiveRate && isInternalUser;
  const isEditable = !enabledAccomInfo && !props.isLiveRate && isInternalUser;
  const isDatesAndOffersFieldEditable = !props.isLiveRate && isInternalUser;
  const showBreakdownHoldBar = !props.isLiveRate && isInternalUser;
  const isSubProductsSalesCostEditable = props.isAdmin || props.isSR;
  const subProductsAreEditable = !enabledAccomInfo && !props.isLiveRate && isInternalUser;
  const isPurchaseCostDisabled = !props.isPurchaseCostEditable || props.isTA;

  let isSalesCostEditable = props.isEditable;
  if (props.isLiveRate) {
    isSalesCostEditable = isSalesCostEditable && (props.isAdmin || props.isSR);
  }

  const networkData = useSelector(BreakdownSelectors.breakdownNetworkRequestsSelector);
  const hotelDetails = useSelector(hotelDetailsSelector);

  const liveAccommodationCancellationPolicies =
    useSelector(BreakdownSelectors.liveAccommodationCancellationPoliciesSelector).find(
      x => x.bookingRef === accom.externalBooking?.bookingReference
    )?.cancellationPolicies || [];

  const isPurchaseCostVisible = props.isSR || props.isFinanceUser;

  const [isCancellationPoliciesOpen, setIsCancellationPoliciesOpen] = useState(false);
  const [isCalendarDatesEdited, setIsCalendarDatesEdited] = useState(false);

  const allOverlappingDates = getOverlappingDates(
    props.allAccommodationItems.filter(item => !isBlank(item.arrival) && !isBlank(item.departure)).map(item => {
      return {
        start: item.arrival,
        end: item.departure,
      };
    })
  );

  const thisAccomDateRange = generateArrayOfDatesBetween(accom.arrival, accom.departure);
  const hasOverlappingDates = hasOverlap(thisAccomDateRange, allOverlappingDates);
  const [hasDismissedOverlapWarning, setHasDismissedOverlapWarning] = useState(false);

  const handleOpenEditAccommodationModal = useCallback(() => {
    dispatch(BreakdownActions.editAccommodationOpenModalAction({ hotelUuid: hotelDetails.uuid, hotelName: hotelDetails.name, roomName: accom.title, headlineLineItemBreakdownAccommodationLineItem: accom }
    , props.bookingUuid, props.index));
  }, [accom]);

  // is editable changes when the breakdown is saved and the parent re-renders.
  // so, on an iseditable change - hide the calendar disclaimer
  useEffect(() => {
    setIsCalendarDatesEdited(false);
  }, [isEditable]);
  const typologyInfoTooltipContent = useCallback(() => {
    return (
      <div style={{ width: '116px' }} className="font-pt-sans text-13px p-2">
        {accom.externalBooking?.status === 'confirmed' && (
          <span>Unique Booking Identification provided by the supplier.</span>
        )}
        {accom.externalBooking?.status === 'cancelled' && (
          <span>Cancelllation ID provided by the supplier. Cancel costs applied.</span>
        )}
      </div>
    );
  }, [accom]);

  const shouldShowExternalBookingDetails =
    accom.roomRateSource === ERateSource.SUPPLIER_CONFIRMED &&
    !!accom.externalBooking?.status &&
    !!accom.externalBooking?.hotelBookingId;

  return (
    <React.Fragment>
      <div className="breakdown-accommodation-product mt-4 mb-4">
        <div className="pt-4 pb-4">
          <div className="accommodation-typology mb-5">
            <p className="font-pt-sans font-bold text-17px leading-22px text-black-true m-0">
              Accommodation {index + 1}
            </p>
            <div className="flex justify-between mt-2">
              <div className="flex space-x-2">
                {accom.roomRateSource === ERateSource.SUPPLIER_CONFIRMED && (
                  <span className="text-xs accommodation-rate bg-teal-40 border border-solid border-teal-80 px-5px py-2px uppercase self-center font-hurmegeometric-sans rounded-1px text-black-true">
                    Live Rate
                  </span>
                )}
                {accom.roomRateSource === ERateSource.SUPPLIER_UNCONFIRMED && (
                  <span className="text-xs bg-yellow-20 border border-solid border-yellow-30 px-5px py-2px uppercase font-hurmegeometric-sans rounded-1px text-black mr-2">
                    Live Rate
                  </span>
                )}
                {(accom.roomRateSource === ERateSource.STATIC || !accom.roomRateSource) && (
                  <span className="text-xs accommodation-rate border border-solid border-gray-50 px-5px py-2px uppercase self-center font-hurmegeometric-sans rounded-1px text-black-true">
                    Static Rate
                  </span>
                )}
                {accom.wasCreatedInstantBook && (
                  <span className="text-xs accommodation-rate border-solid bg-teal-40 border border-teal-80 px-5px py-2px uppercase self-center font-hurmegeometric-sans rounded-1px text-black-true">
                    Instant Book
                  </span>
                )}
              </div>
              {shouldShowExternalBookingDetails && (
                <div className="typology-right-side flex items-center">
                  <HidingTooltip renderTooltipContent={typologyInfoTooltipContent} position="left-bottom">
                    <span className="flex cursor-pointer typology-info-icon">
                      <InfoIcon width="17px" height="17px" fill={theme.colors['gray-80']} />
                    </span>
                  </HidingTooltip>
                  <span className="external-booking-id text-sm font-normal text-black-true tracking-2xs ml-10px">
                    {accom.externalBooking?.status === 'confirmed' && (
                      <span>ID. #{accom.externalBooking?.hotelBookingId}</span>
                    )}
                    {accom.externalBooking?.status === 'cancelled' && (
                      <span>ID. #{accom.externalBooking?.cancellation?.id}</span>
                    )}
                  </span>

                  <span
                    className={classnames(
                      'text-15px rate-source-supplier-confirmed border border-solid px-5px py-2px uppercase font-hurmegeometric-sans text-black-true ml-10px',
                      {
                        'bg-green-25 border-teal-80': accom.externalBooking?.status === 'confirmed',
                        'bg-gray-20 border-gray-60': accom.externalBooking?.status === 'cancelled',
                      }
                    )}
                  >
                    {accom.externalBooking?.status}
                  </span>
                </div>
              )}
              {(showEditIcon || showRemoveIcon) && (
                <span className="flex gap-2 -mt-2 float-right">
                  {showEditIcon && (
                    <div className="edit-accommodation">
                      <span className="block -mt-2">
                        <EditHeadlineButton
                        action={handleOpenEditAccommodationModal}
                        pathPrefix={'Accommodation'}
                          productType={''}
                          index={index}
                        />
                      </span>
                    </div>
                  )}
                  {showRemoveIcon && (
                    <div className="remove-accommodation">
                      <span className="block -mt-2">
                        <RemoveHeadlineButton
                          action={props.removeHeadlineLineItemAction}
                          pathPrefix={'Accommodation'}
                          productType={''}
                          index={index}
                        />
                      </span>
                    </div>
                  )}
                </span>
              )}
            </div>
          </div>
          <table className={`product ${toClassName('Accommodation')} w-full`}>
            <thead>
              <tr>
                <th className="name p-0 w-1/4 text-left">
                  <span className="uppercase text-gray-100 text-xs font-pt-sans">Room Type</span>
                </th>
                <th className="name text-left pl-4" colSpan={isPurchaseCostVisible ? 1 : 2}>
                  <span className="uppercase text-gray-100 text-xs font-pt-sans">Dates</span>
                </th>
                {isPurchaseCostVisible && (
                  <th className="cost-before-discount w-14% text-gray-darker text-xs uppercase text-right pl-2 pr-2 font-pt-sans">
                    <span>Purchase Cost</span>
                  </th>
                )}
                <th className="cost-to-ta w-14% text-gray-darker text-xs uppercase text-right pr-4 font-pt-sans">
                  Cost to TP
                </th>
                {isEditable && <td className="p-0 pb-4 pt-2 w-6"></td>}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="p-0 w-1/4 pr-2 pt-2 pb-4">
                  <AccommodationTopInput
                    placeholder="Room Name"
                    disabled={isEditable === false}
                    key={`Accommodation.items[${index}].title`}
                    className={classnames(
                      'name border text-base border-solid border-gray-40 p-2 w-full font-pt-sans font-bold',
                      {
                        'bg-gray-10': isEditable === false,
                      }
                    )}
                    type="text"
                    value={accom.title}
                    onChange={event => {
                      props.setHeadlineLineItemFieldAction(`Accommodation.items[${index}].title`, event.target.value);
                      dispatch(BreakdownActions.setIsAccommodationModifiedAction(true));
                    }}
                  />
                </td>
                <td colSpan={isPurchaseCostVisible ? 1 : 2} className="p-0 pl-2 pr-2 pt-2 pb-4">
                  <div className="flex flex-row relative items-center">
                    <div
                      style={{
                        width: '272px',
                      }}
                    >
                      <DatePickerStateProvider
                        key={`accom-dates-${index}-${accom.title}-${accom.arrival}-${accom.departure}`}
                        defaultSelectedDates={
                          accom.arrival && accom.departure
                            ? DateHelper.generateSequenceOfDates(
                                accom.arrival,
                                DateFNS.subHours(new Date(accom.departure), 24).toISOString()
                              )
                            : []
                        }
                        onDateChange={(e: string[]) => {
                          const start = e[0];
                          const end = e[e.length - 1];
                          props.setHeadlineLineItemFieldAction(`Accommodation.items[${index}].arrival`, start);
                          props.setHeadlineLineItemFieldAction(`Accommodation.items[${index}].departure`, end);
                          setIsCalendarDatesEdited(true);
                          setHasDismissedOverlapWarning(false);
                          dispatch(BreakdownActions.setIsAccommodationModifiedAction(true));
                        }}
                        render={(params: IDatePickerSateParams) => (
                          <StyledDateRangeInput
                            disabled={isEditable === false}
                            datePickerLeft
                            className={classnames('cursor-pointer text-base p-2 w-full font-pt-sans font-bold', {
                              'bg-gray-10': isEditable === false,
                            })}
                            displayString={params.displayString}
                            currentDate={params.datePickerCurrentDate}
                            totalNights={params.totalNights}
                            selectedDates={params.selectedDates}
                            onDayClick={params.handleDayClick}
                            onDayMouseOver={params.handleDateMouseOver}
                            showDatePicker={params.showDatePicker}
                            onNextClick={params.incrementDate}
                            onPrevClick={params.decrementDate}
                            onMouseDown={params.toggleDatePicker}
                            onClickOutside={params.hideDatePicker}
                            enablePastDates
                          />
                        )}
                      />
                    </div>

                    <span className="flex gap-2">
                      {isCalendarDatesEdited && (
                        <div className="relative w-170px rounded border border-solid bg-yellow-advert-light border-yellow-advert p-2 text-black text-13px">
                          <span>Dates modified. Please check the rest of the items.</span>
                          <button
                            onClick={() => {
                              setIsCalendarDatesEdited(false);
                            }}
                            className="cursor-pointer bg-transparent absolute border-none top-2 right-1"
                          >
                            <SvgIcon
                              IconComponent={CloseIcon}
                              defaultFill={theme.colors['black']}
                              hoverFill={theme.colors['black']}
                              activeFill={theme.colors['black']}
                              width="12px"
                              height="12px"
                            />
                          </button>
                        </div>
                      )}

                      {hasOverlappingDates && !hasDismissedOverlapWarning && (
                        <div className="relative w-170px rounded border border-solid bg-yellow-advert-light border-yellow-advert p-2 text-black text-13px">
                          <span>Rooms seem to overlap. Check if you need to add guests.</span>
                          <button
                            onClick={() => {
                              setHasDismissedOverlapWarning(true);
                            }}
                            className="cursor-pointer bg-transparent absolute border-none top-2 right-1"
                          >
                            <SvgIcon
                              IconComponent={CloseIcon}
                              defaultFill={theme.colors['black']}
                              hoverFill={theme.colors['black']}
                              activeFill={theme.colors['black']}
                              width="12px"
                              height="12px"
                            />
                          </button>
                        </div>
                      )}
                    </span>
                  </div>
                </td>
                {isPurchaseCostVisible && (
                  <td className="p-0 pl-2 pr-2 pt-2 pb-4">
                    <PriceAsCentsInput
                      disabled={isPurchaseCostDisabled}
                      key={`Accommodation.items[${index}].purchaseCostCents`}
                      className={classnames(
                        'purchase-cost bg-blue-10 border text-base border-solid border-gray-40 p-2 w-full font-pt-sans font-bold text-right',
                        {
                          'bg-gray-10': props.isPurchaseCostEditable === false,
                        }
                      )}
                      cents={accom.purchaseCostCents || 0}
                      onBlurUpdate={cents => {
                        props.setHeadlineLineItemFieldAction(`Accommodation.items[${index}].purchaseCostCents`, cents);
                      }}
                      onChange={event => {
                        dispatch(BreakdownActions.setIsAccommodationModifiedAction(true));
                      }}
                    />
                  </td>
                )}
                <td className="p-0 pl-2 pt-2 pb-4">
                  <PriceAsCentsInput
                    disabled={!isSalesCostEditable}
                    key={`Accommodation.items[${index}].saleCostCents`}
                    className={classnames(
                      'cost-to-ta border text-base border-solid border-gray-40 p-2 w-full font-pt-sans font-bold text-right',
                      {
                        'bg-gray-10': !isSalesCostEditable,
                      }
                    )}
                    cents={accom.saleCostCents || 0}
                    onBlurUpdate={val => {
                      props.setHeadlineLineItemFieldAction(`Accommodation.items[${index}].saleCostCents`, val);
                    }}
                    onChange={event => {
                      dispatch(BreakdownActions.setIsAccommodationModifiedAction(true));
                    }}
                  />
                </td>

                {isEditable && <td className="w-[67px]">{/* void - space for the ctas on the rows below */}</td>}
              </tr>
              <tr>
                <td className="p-0 pr-4" colSpan={2}>
                  <AccommodationTopInput
                    placeholder="Dates and Offers"
                    disabled={isDatesAndOffersFieldEditable === false}
                    key={`Accommodation.items[${index}].tertiaryText`}
                    className={classnames(
                      'details border text-base border-solid border-gray-40 p-2 w-full font-pt-sans font-bold',
                      {
                        'bg-gray-10': isDatesAndOffersFieldEditable === false,
                      }
                    )}
                    type="text"
                    value={accom.tertiaryText}
                    onChange={event => {
                      props.setHeadlineLineItemFieldAction(
                        `Accommodation.items[${index}].tertiaryText`,
                        event.target.value
                      );
                      dispatch(BreakdownActions.setIsAccommodationModifiedAction(true));
                    }}
                  />
                </td>
                <td className="text-right p-2">
                  <span className="uppercase text-xs">Subtotal</span>
                </td>
                <td className="text-right pl-2">
                  <span className="subtotal block w-full bg-teal-20 p-2 text-base text-right font-bold">
                    {props.bookingCurrencySymbol}
                    {accom.saleCostCentsUnreactive !== undefined && formatPrice(accom.saleCostCentsUnreactive / 100)}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>

          <ProductsBlock
            titleJSX={<h2 className="text-17px text-left m-0">Meal Plans</h2>}
            key={`Accommodation-${index}-Meal Plan`}
            pathPrefix={`Accommodation.items[${index}]`}
            productType={'Meal Plan'}
            className="mt-4"
            lineItems={accom['Meal Plan'].items}
            titlePlaceholder={'Meal Plan'}
            tertiaryTextPlaceholder={'Guests'}
            setHeadlineLineItemFieldAction={props.setHeadlineLineItemFieldAction}
            removeHeadlineLineItemAction={props.removeHeadlineLineItemAction}
            subTotalCents={accom['Meal Plan'].subtotalCents}
            bookingCurrencySymbol={props.bookingCurrencySymbol}
            isPurchaseCostEditable={props.isPurchaseCostEditable}
            isSR={props.isSR}
            isAdmin={props.isAdmin}
            isFinanceUser={props.isFinanceUser}
            cta={
              isEditable ? (
                <button
                  className="add border border-solid border-brown-80 hover:bg-brown-20 bg-white cursor-pointer font-bold text-sm p-2 rounded text-brown-80 font-pt-sans"
                  onClick={() => {
                    props.addHeadlineLineItemAction(`Accommodation.items[${index}].Meal Plan.items`);
                  }}
                >
                  Add Meal Plan
                </button>
              ) : (
                undefined
              )
            }
            isEditable={subProductsAreEditable}
            isSalesCostEditable={isSubProductsSalesCostEditable}
            isLiveRate={props.isLiveRate}
            isAccommodationSubBlock={true}
          />

          {!isNilOrEmpty(accom.externalBooking) &&
            accom.externalBooking !== null &&
            accom.externalBooking !== undefined && (
              <React.Fragment>
                {props.isLatestVersion && (
                  <div className="flex flex-row justify-between mt-2 pr-4">
                    {networkData.getLiveAccommodationCancellationDataLoad === ENetworkRequestStatus.SUCCESS &&
                      !props.isTA &&
                      accom.externalBooking.status !== 'enquiry' && (
                        <FluidButton
                          className="live-accommodation-request-cancellation"
                          type="secondary"
                          disabled={bookingStatus !== EBookingStatus.CONFIRMED}
                          onClick={() => {
                            dispatch(BreakdownActions.resetLiveAccommodationCancellationModalAction());
                            dispatch(
                              BreakdownActions.addLiveAccommodationCancellationModalAccomAction(
                                accom.externalBooking!.bookingReference
                              )
                            );
                            dispatch(BreakdownActions.setLiveAccommodationCancellationModalIsOpenAction(true));
                          }}
                        >
                          Request Cancellation
                        </FluidButton>
                      )}
                    {(networkData.getLiveAccommodationCancellationDataLoad !== ENetworkRequestStatus.SUCCESS ||
                      props.isTA ||
                      accom.externalBooking.status === 'enquiry') && <div>{/* deliberately blank for layout */}</div>}
                    <button
                      onClick={() => {
                        setIsCancellationPoliciesOpen(i => !i);
                      }}
                      className="bg-white border-none text-brown-100 cursor-pointer hover:text-brown-130"
                    >
                      <span className="flex flex-row items-center">
                        {networkData.getLiveAccommodationCancellationDataLoad === ENetworkRequestStatus.PENDING && (
                          <span className="underline text-15px leading-20px">Loading Cancellation Details</span>
                        )}
                        {networkData.getLiveAccommodationCancellationDataLoad !== ENetworkRequestStatus.PENDING && (
                          <React.Fragment>
                            <span className="underline text-15px leading-20px">See Cancellation Details</span>
                            <i
                              className={classnames('fas relative ml-2', {
                                'fa-chevron-down': !isCancellationPoliciesOpen,
                                'fa-chevron-up': isCancellationPoliciesOpen,
                              })}
                            ></i>
                          </React.Fragment>
                        )}
                      </span>
                    </button>
                  </div>
                )}

                {isCancellationPoliciesOpen && (
                  <div className="bg-ivory font-pt-sans border border-solid border-gray-20 mt-5 py-10px px-15px">
                    <span className="block text-15px leading-21px font-bold">Cancellation Cost</span>
                    {networkData.getLiveAccommodationCancellationDataLoad === ENetworkRequestStatus.ERROR && (
                      <span className="text-15px leading-20px text-red-95">Error loading cancellation details</span>
                    )}
                    {!!liveAccommodationCancellationPolicies.length && (
                      <>
                        <ul>
                          {liveAccommodationCancellationPolicies.map(cp => {
                            return <li className="text-15px leading-21px">{cp} *</li>;
                          })}
                        </ul>
                        <span className="footnote-on-cancellation-policies block text-sm leading-20px text-black-true">
                          ( * ) Time at destination
                        </span>
                      </>
                    )}
                    {!liveAccommodationCancellationPolicies.length && (
                      <span className="text-15px leading-21px inline-block mt-1">
                        Please, confirm the cancellation policy of this rate with your sales contact.
                      </span>
                    )}
                  </div>
                )}
              </React.Fragment>
            )}

          <ProductsBlock
            titleJSX={<h2 className="text-17px text-left m-0">Extra Person Supplements</h2>}
            key={`Accommodation-${index}-Supplement`}
            pathPrefix={`Accommodation.items[${index}]`}
            productType={'Supplement'}
            className="mt-4"
            lineItems={accom.Supplement.items}
            setHeadlineLineItemFieldAction={props.setHeadlineLineItemFieldAction}
            removeHeadlineLineItemAction={props.removeHeadlineLineItemAction}
            subTotalCents={accom.Supplement.subtotalCents}
            bookingCurrencySymbol={props.bookingCurrencySymbol}
            isPurchaseCostEditable={props.isPurchaseCostEditable}
            isSR={props.isSR}
            isAdmin={props.isAdmin}
            isFinanceUser={props.isFinanceUser}
            cta={
              isEditable ? (
                <button
                  className="add border border-solid border-brown-80 hover:bg-brown-20 bg-white cursor-pointer font-bold text-sm p-2 rounded text-brown-80 font-pt-sans"
                  onClick={() => {
                    props.addHeadlineLineItemAction(`Accommodation.items[${index}].Supplement.items`);
                  }}
                >
                  Add Extra Person Supplement
                </button>
              ) : (
                undefined
              )
            }
            isEditable={subProductsAreEditable}
            isSalesCostEditable={isSubProductsSalesCostEditable}
            isLiveRate={props.isLiveRate}
            isAccommodationSubBlock={true}
          />
        </div>

        {(bookingStatus === EBookingStatus.ENQUIRY ||
          bookingStatus === EBookingStatus.REQUESTED ||
          bookingStatus === EBookingStatus.CONFIRMED) &&
          showBreakdownHoldBar && (
            <BreakdownHoldBar
              accommodationIndex={index}
              hold={accom.hold}
              setBreakdownHold={props.setBreakdownHoldAction}
            />
          )}
      </div>
    </React.Fragment>
  );
};
