import React, { useState, useEffect } from 'react';
import styles from './RadioButtons.module.css';
import classNames from 'classnames';

export interface IRadioButtonProps {
  id?: string;
  options: IOption[];
  onChange: (value: string, label?: React.ReactNode) => void;
  selectedOption?: string;
  containerClasses?: string;
  labelClasses?: string;
  hasDefault?: boolean;
}

// @TODO rewrite using Tailwind only
export interface IOption {
  label: React.ReactNode;
  value: string;
}

export const RadioButtons: React.FC<IRadioButtonProps> = (props: IRadioButtonProps) => {
  const handleOptionChange = (event): void => {
    const value = event.target.value;
    const option = props.options.find(item => item.value === value);
    props.onChange(value, option?.label ?? undefined);
  };

  return (
    <div className={classNames('justify-start items-start gap-[10px]', props.containerClasses)}>
      {props.options.map((option, index) => (
        <label key={option.value} className={classNames('flex', styles.container, props.labelClasses)}>
          <div>
            <input
              type="radio"
              value={option.value}
              checked={props.selectedOption === option.value}
              onChange={handleOptionChange}
            />
            <span id={`${props.id}-${index}`} className={styles.checkmark}></span>
          </div>
          {option.label}
        </label>
      ))}
    </div>
  );
};

export const RadioButtonSingle = ({
  id,
  onSelect,
  isSelected,
  value,
}: {
  id: string;
  onSelect: () => void;
  isSelected: boolean;
  value: string | number;
}) => {
  return (
    <label key={id} className={classNames('flex', styles.container)}>
      <div>
        <input type="radio" value={value} checked={isSelected} onChange={onSelect} />
        <span id={id + 'checkmark'} className={styles.checkmark}></span>
      </div>
    </label>
  );
};
