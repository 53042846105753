// returns new array with elements of xs separated by sep
export const interpose = <T, S>(xs: T[], sep: S): (T | S)[] => xs.flatMap(x => [sep, x]).slice(1);

export const hasOverlap = <T>(arr1: T[], arr2: T[]): boolean => {
  const set1 = new Set(arr1);
  return arr2.some(item => set1.has(item));
};

export const notNull = <T>(val: T | null): val is T => {
  return val !== null;
};

export const notNullOrUndefined = <T>(val: T | null | undefined): val is T => {
  return val !== null && val !== undefined;
};
