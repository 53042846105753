import { useQuery } from "@tanstack/react-query";
import { makeCRMApi } from "../CrmApi";
import { AxiosResponse } from "axios";
import { ETypeValueType, ITypeValue } from "../types/types";

export const useCrmTypeValues = (type: ETypeValueType, { enabled = true }: { enabled: boolean }) => {
  const crmApi = makeCRMApi();

  const crmTypeValues = useQuery<AxiosResponse<ITypeValue[]>>({
    queryKey: ['crm-type-values', type, enabled],
    queryFn: () =>
      crmApi.postTypeValues(type, { enabled }),
  });

  return crmTypeValues;
}
