import React from 'react';
import { RHPSubtitle } from './RHPSubtitle';
import { IBookingError } from 'services/BackendApi';
import classNames from 'classnames';

export interface IRHPProductsProps {
  title: string;
  products: { uuid: string; name: string }[];
  isVisible: boolean;
  errors: IBookingError[];
}

export const RHPProducts: React.FC<IRHPProductsProps> = ({ title, products, isVisible, errors }) => {
  if (!isVisible) {
    return null;
  }

  return (
    <div className="rhp-transfers">
      <RHPSubtitle>{title}</RHPSubtitle>
      {products.length === 0 ? (
        <p className="font-pt-sans text-[15px] italic leading-[normal] text-gray-100 mt-[5px] mb-0">None</p>
      ) : (
        products.map(item => (
          <p key={item.uuid} className="rhp-product m-0 mt-[5px]">
            {item.name}
          </p>
        ))
      )}
      {errors.length > 0 && (
        <div className="flex flex-col gap-[5px]">
          {errors.map(error => (
            <p
              className={classNames('font-pt-sans text-[13px] italic leading-[15px] mt-[5px] mb-0', {
                'text-red-95': error.type === 'booking',
                'text-yellow-60': error.type === 'warn',
              })}
            >
              {error.message}
            </p>
          ))}
        </div>
      )}
    </div>
  );
};
